import { CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CImg, CTooltip } from "@coreui/react"
import { observer } from "mobx-react"
import React, { Component } from "react"
import plus from "../../../assets/1.png"
import cross from "../../../assets/2.png"
const enhanceWithClickOutside = require('react-click-outside')

interface PropsType {
    name: string
    toggleForm(): void
}

interface StateType {
    isButtonToggle: boolean
}

@observer class EditButton extends Component<PropsType, StateType> {
            constructor(props: PropsType) {
                super(props)
                this.state = {
                    isButtonToggle: false
                } 
                this.toggleButton = this.toggleButton.bind(this)
                this.handleClickOutside = this.handleClickOutside.bind(this)
            }
            toggleButton() {
                this.setState({
                    isButtonToggle: !this.state.isButtonToggle
                })
            }
            handleClickOutside() {
                this.setState({
                    isButtonToggle: false
                })  
            }
            render() {
                return (
                    <CDropdown className="mr-2">
                        <CTooltip
                            content="Добавить элемент"
                        >
                            <CDropdownToggle color="danger" caret={false} onClick={this.toggleButton} className="p-2 rounded-circle">
                                { this.state.isButtonToggle ? <CImg src={cross} className="p-1"/> : <CImg src={plus} className="p-1"/> }
                            </CDropdownToggle> 
                        </CTooltip>
                        <CDropdownMenu>
                            <CDropdownItem 
                                onClick={() => {
                                    this.setState({isButtonToggle: false});
                                    this.props.toggleForm()
                                }} 
                                to={`/${this.props.name}/new`}
                            >
                                Добавить
                            </CDropdownItem>
                        </CDropdownMenu>
                    </CDropdown>
                )
            }
}

export default enhanceWithClickOutside(EditButton)