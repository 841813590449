import { CButton } from '@coreui/react'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import CIcon from '@coreui/icons-react'

interface PropsType extends RouteComponentProps {
    schema: any
    createTab(item: any, window: string): void
}

@observer class Schema extends Component<PropsType> {
            render() {
                return (
                    <div className="d-flex justify-content-center p-1">
                        <div className="d-flex mt-4">
                            <div className="d-flex flex-column">
                               {this.props.schema.testButton.map((item: any, index: number) => {
                                return  <div key={index} className="d-flex flex-row">
                                            <CButton color="warning" className="rounded-0 mb-2 flex-shrink-0" size="xl">
                                                {item.name}
                                            </CButton>
                                            <CIcon name="cil-arrow-right" size="lg" className="m-2 flex-shrink-0"/>
                                        </div>   
                                        
                                })}
                                 
                            </div>
                        </div>
                        <div className="d-flex flex-column ml-2 mt-1">
                            <div className="d-flex flex-column">
                                <div className="d-flex">
                                    <p className="mt-1 ml-0">{this.props.schema.modelName}</p>
                                    <CIcon name="cil-arrow-bottom" size="lg" className="ml-4 align-self-center"/>
                                </div>
                               {this.props.schema.phases.map((item: any, index: number) => {
                                    return  <div key={index} className="d-flex flex-column">
                                                <CButton color="light" className="rounded-0 mb-2 flex-shrink-0 text-nowrap" size="sm" onClick={() => this.props.createTab(item, 'second')}>
                                                    <h6 className="text-right">in: {item.in}</h6>
                                                    {item.name}
                                                    <h6 className="text-right pt-3">out: {item.out}</h6> 
                                                </CButton> 
                                                {index !== this.props.schema.testButton.length && <CIcon name="cil-arrow-bottom" size="lg" className="m-2 align-self-center"/>}
                                            </div>   
                                            
                                })} 
                            </div>   
                        </div>
                    </div>
                )
            }
} 

export default withRouter(Schema)