import { CButtonClose, CModal, CModalBody, CNav, CNavItem } from '@coreui/react'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { billingBalanceController } from '../../controller/OrmBillingBalanceController'
import { MatchType } from '../containers/UserLoginContainer'

interface PropsType extends RouteComponentProps {
    match: MatchType
    isModalOpen: boolean
    loadPage(url: string): void
    toggleModal(): void
    result: boolean
}

@observer class PaymentResult extends Component<PropsType> {
            componentDidMount() {
                document.title = 'Пополнение баланса'
                this.props.loadPage(this.props.match.url)
            }
            render() { 
                return (
                    <CModal
                        centered
                        show={this.props.isModalOpen}
                        onClose={() => {
                            this.props.toggleModal();
                            this.props.history.push("/");
                            billingBalanceController.getBillingBalancePoints();
                            localStorage.removeItem('isRefillingBalance');
                            document.title = 'Личный кабинет НАТИВИ'
                        }}
                        size="lg"
                    >
                        <CModalBody>
                            <CNav variant="tabs" className="border-0">
                                <CNavItem className="ml-auto">
                                    <CButtonClose 
                                        onClick={() => {
                                            this.props.toggleModal();
                                            this.props.history.push("/");
                                            billingBalanceController.getBillingBalancePoints();
                                            localStorage.removeItem('isRefillingBalance');
                                            document.title = 'Личный кабинет НАТИВИ'
                                    }}/>
                                </CNavItem>
                            </CNav>
                            {this.props.result ? <h1>Оплата прошла успешно!</h1>: <h1>Ошибка при оплате!</h1>}
                        </CModalBody>
                    </CModal>
                )
            }
} 

export default withRouter(PaymentResult)