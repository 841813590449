import { CModal, CModalBody } from '@coreui/react'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { UseLocation } from '../../support/useLocationHoC'
import { MatchType } from '../containers/UserLoginContainer'

interface PropsType extends RouteComponentProps {
    match: MatchType
    query: any
    isModalOpen: boolean
    loadPage(url: string, operationId?: string): void
}

@observer class PaymentValidation extends Component<PropsType> {
            componentDidMount() {
                document.title = 'Пополнение баланса'
                const query = this.props.query
                const operationId = query.get('operationId') as any
                this.props.loadPage(this.props.match.url, operationId)
            }
            render() { 
                return (
                    <CModal
                        centered
                        show={this.props.isModalOpen}
                        size="lg"
                    >
                        <CModalBody>
                            <h1>Подождите...</h1>
                            <h1>Производится оплата!</h1>
                        </CModalBody>
                    </CModal>
                )
            }
} 

export default  UseLocation(withRouter(PaymentValidation))