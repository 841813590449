import { CButton, CCard, CCardBody, CCardFooter, CCardHeader, CForm, CFormGroup, CFormText, CLink } from '@coreui/react'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { UserLoginType } from '../../store/OrmUserStore'
import StringLoginInput from '../ChangeFormInputs/StringLoginInput'

interface PropsType {
    userLogin: UserLoginType
    login(loginData: {email: string, password: string}): void
    resetLoginData(): void
    toggleRegistrationModal(): void
}

@observer class UserLogin extends Component<PropsType> {
    constructor(props: PropsType) {
        super(props)
        this.submit = this.submit.bind(this)
    }
    componentDidMount() {
        document.title = 'Вход в личный кабинет НАТИВИ'
    }
    submit(e: React.FormEvent<HTMLButtonElement> ) {
        e.preventDefault()
        const loginData = {
            email: this.props.userLogin.email,
            password: this.props.userLogin.password
        }
        this.props.login(loginData)
    }
    render() { 
        return (
            <div className="justify-content-center align-items-center c-app bg-dark">
                <CCard className="m-3 p-2 d-flex flex-column align-items-center">
                    <CCardHeader className="border-0 font-weight-bold">
                        <h4>Авторизируйтесь!</h4>
                    </CCardHeader>
                    <CCardBody className="p-4">
                        <CForm>
                            <StringLoginInput
                                type="email"  
                                name="email" 
                                placeholder="Email"
                                iconName="cil-envelope-closed"
                                autoComplete="username"
                                object={this.props.userLogin}
                                size="lg"
                            />
                            <StringLoginInput
                                type="password"  
                                name="password" 
                                placeholder="Password"
                                iconName="cil-asterisk"
                                autoComplete="current-password"
                                object={this.props.userLogin}
                                size="lg"
                            />
                            <CFormText className="p-1">
                                { 
                                    this.props.userLogin.loginStatusCode === 401 ? <h6 className="text-warning">Проверьте правильность ввода email или пароля</h6> : 
                                    this.props.userLogin.loginStatusCode === 400 ? <h6 className="text-warning">Поля email  и пароль обязательны для заполнения</h6> :
                                    ""
                                }
                            </CFormText>
                            <CFormGroup className="form-actions d-flex justify-content-center">
                                <CButton 
                                    type="submit" 
                                    size="lg" 
                                    color="danger" 
                                    onClick={this.submit}
                                >
                                    Войти
                                </CButton>
                            </CFormGroup>
                        </CForm>
                    </CCardBody>
                    <CCardFooter>
                        Еще нет учетной записи? <CLink to="/login/registration" onClick={() => {this.props.toggleRegistrationModal(); this.props.resetLoginData()}}>Регистрация.</CLink>   
                    </CCardFooter>
                </CCard>
            </div>      
        )
    }
} 

export default UserLogin