import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import Configurator from '../configurator/Configurator'
import PaymentResult from '../refill/PaymentResult'
import PaymentValidation from '../refill/PaymentValidation'
import ExtensionsContainer from './ExtensionsContainer'
import MicroservicesContainer from './MicroservicesContainer'
import RefillContainer from './RefillContainer'
import RequestsContainer from './RequestsContainer'
import ServersContainer from './ServersContainer'
import WorkplacesContainer from './WorkplacesContainer'

interface PropsType {
    isRefillModalOpen: boolean
    isPaymentValidationModalOpen: boolean
    toggleRefillModal(status: boolean): void
    loadPage(url: string, operationId?: string): void
    isPaymentResultModalOpen: boolean
    togglePaymentResultModal(): void
    result: boolean
    isRefillingBalance: boolean
}

class Content extends Component<PropsType> {
    render() {
        return (
            <div>
                <Switch>
                    <Route path="/servers" render={ () => <ServersContainer/>}/>
                    <Route path="/workplaces" render={ () => <WorkplacesContainer/>}/>
                    <Route path="/microservices" render={ () => <MicroservicesContainer/>}/>
                    <Route path="/extensions" render={ () => <ExtensionsContainer/>}/>
                    <Route path="/requests" render={ () => <RequestsContainer/>}/>
                    <Route path="/configurator" render={ () => <Configurator/>}/>
                    <Route path="/refill" render={ () =>    <RefillContainer 
                                                                toggleRefillModal={this.props.toggleRefillModal} 
                                                                isRefillModalOpen={this.props.isRefillModalOpen}
                                                            />}/>
                    <Route path="/payment/validate" render={ () =>  <PaymentValidation 
                                                                        loadPage={this.props.loadPage} 
                                                                        isModalOpen={this.props.isPaymentValidationModalOpen}
                                                                    />}/>
                    {this.props.isRefillingBalance &&   <React.Fragment>                                                
                                                            <Route exact path="/payment/success" render={ () =>     <PaymentResult 
                                                                                                                        loadPage={this.props.loadPage} 
                                                                                                                        isModalOpen={this.props.isPaymentResultModalOpen} 
                                                                                                                        toggleModal={this.props.togglePaymentResultModal} 
                                                                                                                        result={this.props.result}
                                                                                                                    />}/>
                                                            <Route exact path="/payment/failure" render={ () =>     <PaymentResult 
                                                                                                                        loadPage={this.props.loadPage} 
                                                                                                                        isModalOpen={this.props.isPaymentResultModalOpen} 
                                                                                                                        toggleModal={this.props.togglePaymentResultModal} 
                                                                                                                        result={this.props.result}
                                                                                                                    />}/>
                                                        </React.Fragment>
                    }
                </Switch>   
            </div>
        )
    }
}

export default Content