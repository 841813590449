import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom'
import { serverController } from '../../controller/OrmServerController'
import { OrmServerStoreType } from '../../store/OrmServerStore'
import Servers from '../servers/Servers'
import ServersChangeForm from '../servers/ServersChangeForm'

export interface MatchType {
    isExact: boolean
    params: {elementId: string}
    path:string
    url: string
}

interface PropsType extends RouteComponentProps {
    
}

interface InjectedProps extends PropsType{
    serverStore: OrmServerStoreType
}

interface StateType {
    isFormOpen: boolean
}

@inject('serverStore')
@observer class ServersContainer extends Component<PropsType, StateType> {
    constructor(props: PropsType) {
        super(props)
        this.state = {
            isFormOpen: false
        }
        this.loadPage = this.loadPage.bind(this)
        this.toggleForm = this.toggleForm.bind(this)
        this.addDomain = this.addDomain.bind(this)
        this.confirmDomain = this.confirmDomain.bind(this)
        this.resetFormData = this.resetFormData.bind(this)
    }

    get injected() {
        return this.props as InjectedProps
    }

    loadPage(elementId: string, url: string, page: string) {
        if ( url === '/servers' ) {
            document.title = 'Личный кабинет НАТИВИ: Серверы'
            serverController.getData(localStorage.getItem('userId'))
        } else if ( url === `/servers/${elementId}` ) {
            this.setState({
                isFormOpen: true
            })
            if ( elementId !== 'new' ) {
                serverController.getServer(localStorage.getItem('userId'), Number(elementId))
            }
        }
    }

    changeCurrentPage(page: number) {
        if ( page !== 0 ) {
            
        } 
    }

    toggleForm() {
        this.setState({
            isFormOpen: !this.state.isFormOpen
        })
    }

    editServer(data: any, index: number) {
        serverController.editData(localStorage.getItem('userId'), data, index)
    }

    addDomain(domain: any) {
        this.injected.serverStore.addDomain(domain)
    }

    confirmDomain(index: number) {
        this.injected.serverStore.confirmDomain(index)
    }

    saveData(data: any) {
        serverController.postData(localStorage.getItem('userId'), data)
    }

    resetFormData() {
        this.injected.serverStore.resetFormData()
    }

    render() { 
        const props = {
            servers: this.injected.serverStore.servers,
            server: this.injected.serverStore.server,
            isFormOpen: this.state.isFormOpen,
            editServer: this.editServer,
            loadPage: this.loadPage,
            changeCurrentPage: this.changeCurrentPage,
            toggleForm: this.toggleForm,
            addDomain: this.addDomain,
            confirmDomain: this.confirmDomain,
            saveData: this.saveData,
            resetFormData: this.resetFormData
        }
        return (
            <Switch>
                <Route exact path="/servers" render={ () =>   <Servers {...props}/>}/>
                <Route exact path="/servers/:elementId" render={ () =>  <ServersChangeForm {...props}/>}/>
            </Switch>
        )
    }
} 

export default withRouter(ServersContainer)
