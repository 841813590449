import { CButton, CButtonClose, CForm, CModal, CModalBody, CModalFooter, CModalHeader, CNav, CNavItem } from '@coreui/react'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import StringLoginInput from '../ChangeFormInputs/StringLoginInput'
import { UserLoginType } from '../../store/OrmUserStore'
import { MatchType } from '../containers/UserLoginContainer'

interface PropsType extends RouteComponentProps {
    match: MatchType
    isModalOpen: boolean
    userLogin: UserLoginType
    toggleModal(): void
    resetLoginData(): void
    register(registrationData: any): void
    loadPage(url: string): void
}

@observer class Registration extends Component<PropsType> {
            constructor(props: PropsType) {
                super(props)
                this.submit = this.submit.bind(this)
            }
            componentDidMount() {
                document.title = 'Регистрация в сервисе НАТИВИ'
                this.props.loadPage(this.props.match.url)
            }
            submit() { 
                const registrationData = {
                    email: this.props.userLogin.email,
                    password: this.props.userLogin.password,
                    fullName: this.props.userLogin.fullName
                }
                this.props.register(registrationData)
            }
            render() { 
                return (
                    <CModal
                        className="modal-registration"
                        centered
                        show={this.props.isModalOpen}
                        onClose={() => {
                            this.props.toggleModal();
                            this.props.history.push("/login")
                        }}
                        size="lg"
                    >
                        <CModalHeader className="border-0 font-weight-bold pb-0">
                            <h5>Зарегистрируйтесь!</h5>
                            <CNav variant="tabs" className="mb-4 border-0">
                                <CNavItem className="ml-auto">
                                    <CButtonClose 
                                        onClick={() => {
                                            this.props.toggleModal() ;
                                            this.props.history.push("/login")
                                    }}/>
                                </CNavItem>
                            </CNav>
                        </CModalHeader>
                        <CModalBody>
                            <CForm>
                                <StringLoginInput
                                    type="email"  
                                    name="email" 
                                    placeholder="Email"
                                    iconName="cil-envelope-closed"
                                    autoComplete="username"
                                    object={this.props.userLogin}
                                    size="lg"
                                />
                                <StringLoginInput
                                    type="password"  
                                    name="password" 
                                    placeholder="Password"
                                    iconName="cil-asterisk"
                                    autoComplete="new-password"
                                    object={this.props.userLogin}
                                    size="lg"
                                />
                                <StringLoginInput
                                    invalid={true}
                                    type="password"  
                                    name="repeatPassword" 
                                    placeholder="Repeat password"
                                    iconName="cil-asterisk"
                                    autoComplete="new-password"
                                    object={this.props.userLogin}
                                    size="lg"
                                />
                                <StringLoginInput
                                    type="text"  
                                    name="fullName" 
                                    placeholder="ФИО"
                                    iconName="cil-user"
                                    object={this.props.userLogin}
                                    size="lg"
                                />
                            </CForm>
                        </CModalBody>
                        <CModalFooter className="border-0">
                            <CButton 
                                type="button" 
                                size="lg" 
                                color="danger"
                                className="w-100" 
                                onClick={this.submit} 
                                disabled={this.props.userLogin.password !== this.props.userLogin.repeatPassword || this.props.userLogin.password === '' || this.props.userLogin.email === '' ? true: false}
                            >
                                Создать учетную запись!
                            </CButton>
                        </CModalFooter>
                    </CModal>
                )
            }
} 

export default withRouter(Registration)