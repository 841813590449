import { observable, action, makeObservable, set } from "mobx"

export interface UserBalanceType {
    pointBalanceConverted: number
    amount: number
    result: boolean
    isRefillingBalance: boolean
}

export interface UserLoginType {
    email: string
    password: string
    repeatPassword: string
    firstName: string
    lastName: string
    fullName: string
    isAuth: boolean
    isRemember: boolean
    loginStatusCode: number
    isRegistrationSuccess: boolean
    user: any
}

export interface OrmUserStoreType {
    userLogin: UserLoginType
    userBalance: UserBalanceType
    eraseData(): void
    setValue(model: any, key: string, value: any): void
}

class OrmUserStore implements OrmUserStoreType {
    constructor() {
        makeObservable(this, {
            userLogin: observable,
            userBalance: observable,
            eraseData: action,
            setValue: action
        })
    }
    userLogin = {
        email: '',
        password: '',
        repeatPassword: '',
        firstName: '',
        lastName: '',
        fullName: '',
        isAuth: false,
        isRemember: false,
        loginStatusCode: 0,
        isRegistrationSuccess: false,
        user: {}
    }
    userBalance = {
        pointBalanceConverted: 0,
        amount: 0,
        result: false,
        isRefillingBalance: false
    }
    setValue(model: any, key: string, value: any) {
        set(model, key, value)
    }
    eraseData() {
        this.userLogin.email = ''
        this.userLogin.password = ''
        this.userLogin.loginStatusCode = 0
    }
}

export const userStore = new OrmUserStore()

export default OrmUserStore

