import firebase from 'firebase'
import { serverStore } from '../store/OrmServerStore'

const firebaseConfig = {
    apiKey: "AIzaSyC4gXacSbuh_CPmsogY_uQmA5b7y7ekZNI",
    authDomain: "lknatiwi.firebaseapp.com",
    projectId: "lknatiwi",
    storageBucket: "lknatiwi.appspot.com",
    messagingSenderId: "188857250021",
    appId: "1:188857250021:web:3859e64f6af372335a240c"
}
  
firebase.initializeApp(firebaseConfig)
const db = firebase.firestore()

class OrmServerController {
    
    registerNewUser(userId: string | null) {
        db.collection('servers').doc(`${userId}`).set({}, { merge: true })
    }

    getData(userId: string | null) {
        db.collection('servers').doc(`${userId}`).get().then((doc) => {
            serverStore.setValue(serverStore.servers, 'list', doc.data()?.servers !== undefined ? doc.data()?.servers : [] )
        })
    }

    getServer(userId: string | null, index: number) {
        db.collection('servers').doc(`${userId}`).get().then((doc) => {
            serverStore.setValue(serverStore, 'server', doc.data()?.servers !== undefined ? doc.data()?.servers[index] : {} )
        })
    }

    postData(userId: string | null, data: any) {
        db.collection('servers').doc(`${userId}`).update({servers: firebase.firestore.FieldValue.arrayUnion(data)}).then(() => {
            console.log('GOOD')
        })
    }

    editData(userId: string | null, data: any, index: number) {
        db.collection('servers').doc(`${userId}`).get().then((doc) => {
            let servers = doc.data()?.servers
            servers.splice(index, 1, data)
            db.collection('servers').doc(`${userId}`).update({servers}).then(() => {
                console.log('GOOD')
            })
        })
    }
}

export const serverController = new OrmServerController()

export default OrmServerController