import { runInAction } from 'mobx'
import React, { Component } from 'react'

interface PropsType {
  object: any
  name: string
  propertyName: string
  modelsList: Array<any>
  size?: string
  row?: boolean
  modelName?: string
  updateData?: (fieldName: string, item: any) => void
  inputClassName?: string
}

abstract class BaseSimpleSelect<T> extends Component<PropsType> {
  constructor(props: PropsType) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }
  handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    let value: T = this.parseValue(e.target.value)
    this.setValue(value)
  }
  
  setValue(value: T) {
    let object: any = this.props.object
    runInAction(() => {
      object[this.props.name] = value
      if ( this.props.modelName === 'configurator' && this.props.updateData !== undefined ) {
        this.props.updateData(this.props.name, object[this.props.name])
      }
    })
  }
  
  getValue(): T {
    let object: any = this.props.object
    return object[this.props.name]
  }

  abstract parseValue(value: string): T

  abstract renderValue(value: T): string
}

export default BaseSimpleSelect
