import axios from "axios"
import { config } from '../baseUrls'

const instance = axios.create({
    withCredentials: true,
    baseURL: config.url.API_URL
})

class OrmBillingBalanceApi {
    getBillingBalancePoints() {
        return instance.get(`billingBalancePoints/currentBalance`, {
            headers: {
                Authorization: localStorage.getItem('id')
            }
        })
    }
    refillBalance(amount: number) {
        return instance.post('billingBalanceIncomeOperations/process', {amount}, {
            headers: {
                Authorization: localStorage.getItem('id')
            }
        })
    }
    performRefillBalance(performingData: any) {
        return instance.post('paymentOptions/perform', performingData, {
            headers: {
                Authorization: localStorage.getItem('id')
            }
        })
    }
    performRefillBalanceValidate(operationId: string | null) {
        return instance.post('paymentOptions/validate', {operationId}, {
            headers: {
                Authorization: localStorage.getItem('id')
            }
        })
    }
}

export const billingBalanceApi = new OrmBillingBalanceApi()

export default OrmBillingBalanceApi