import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { billingBalanceController } from '../../controller/OrmBillingBalanceController'
import { OrmUserStoreType } from '../../store/OrmUserStore'
import Refill from '../refill/Refill'

export interface MatchType {
    isExact: boolean
    params: {elementId: string}
    path:string
    url: string
}
interface PropsType extends RouteComponentProps {
    match: MatchType
    isRefillModalOpen: boolean
    toggleRefillModal(status: boolean): void
}

interface InjectedProps extends PropsType{
    userStore: OrmUserStoreType
}


@inject('userStore')
@observer class RefillContainer extends Component<PropsType> {
    constructor(props: PropsType) {
        super(props)
        this.loadPage = this.loadPage.bind(this)
    }
    get injected() {
        return this.props as InjectedProps
    }
    
    // This method is controlling url state

    loadPage(url: string) {
        if ( url === '/refill' ) {
           this.props.toggleRefillModal(true)
        } 
    }
    refillBalance(amount: number) {
        billingBalanceController.refillBalance(amount)
    }
    render() { 
        return (
            <Refill
                loadPage={this.loadPage}
                toggleModal={this.props.toggleRefillModal}
                isModalOpen={this.props.isRefillModalOpen}
                userBalance={this.injected.userStore.userBalance}
                refillBalance={this.refillBalance}
            />
        )
    }
} 

export default withRouter(RefillContainer)
