import { CImg, CSidebar, CSidebarBrand, CSidebarNav, CSidebarNavItem } from '@coreui/react';
import React, { Component } from 'react'
import logo from "../assets/logo.svg"

interface PropsType {
    minimizeSidebar(): void
    isMinimized: boolean
    isShown: boolean
}
class Sidebar extends Component<PropsType> {

    render() {
        return (
            <CSidebar size='sm' minimize={this.props.isMinimized} show={this.props.isShown}>
                <CSidebarBrand to={"/"}>
                    <CImg src={logo} width="100"/>
                </CSidebarBrand>
                <CSidebarNav>
                    <CSidebarNavItem name="Серверы" to="/servers" icon="cil-laptop"/>
                    <CSidebarNavItem name="Рабочие места" to="/workplaces" icon="cil-monitor"/>
                    <CSidebarNavItem name="Микросервисы" to="/microservices" icon="cil-cloud"/>
                    <CSidebarNavItem name="Расширения" to="/extensions" icon="cil-options"/>
                    <CSidebarNavItem name="Заявки" to="/requests" icon="cil-clipboard"/>
                </CSidebarNav> 
            </CSidebar>
        )
    }
}

export default Sidebar