import { observer } from 'mobx-react'
import BaseSimpleLoginInput from './BaseSimpleLoginInput'

@observer class StringLoginInput extends BaseSimpleLoginInput<string> {

  parseValue(value: string): string {
    return value
  }

  renderValue(value: string): string {
    return value || ''
  }
}

export default StringLoginInput
