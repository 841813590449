import { 
        CButton, 
        CButtonToolbar, 
        CCard, 
        CCardBody, 
        CCol, 
        CCollapse, 
        CDataTable,  
        CFormGroup,  
        CImg,  
        CTooltip 
        } from '@coreui/react'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import plus from "../../assets/1.png"
import cross from "../../assets/2.png"
import { ServersType, ServerType } from '../../store/OrmServerStore'
import StringInput from '../ChangeFormInputs/StringInput'


interface PropsType {
    servers: ServersType
    server: ServerType
    addDomain(domain: any): void
    confirmDomain(index: number): void
}

interface StateType {
    collapses: Array<number>
    isConfirm: boolean
}

@observer class DomainsTab extends Component<PropsType, StateType> {
            constructor(props: PropsType) {
                super(props)
                this.state = {
                    collapses: [],
                    isConfirm: false
                }
                this.toggleAddForm = this.toggleAddForm.bind(this)
                this.addDomain = this.addDomain.bind(this)
            }
            toggleAddForm(index: number) {
                const position = this.state.collapses.indexOf(index);
                let newDetails = this.state.collapses.slice();
                if (position !== -1) {
                    newDetails.splice(position, 1)
                } else {
                    newDetails = [index]
                }
                this.setState({
                    collapses: newDetails
                })
            }
            addDomain() {
                const domain = {
                    name: this.props.server.domain,
                    confirm: false
                }
                this.props.addDomain(domain)
            }
            render() { 
                const copyItems = this.props.server.domains.slice()
                const fields: Array<string> = ['name', 'action']
                const fieldsNames = {'name': 'Наименование', 'action': 'Действие'}
                return (
                    <div>
                        <CButtonToolbar className="d-flex">
                            <CTooltip
                                content="Добавить строку"
                            >
                                <CButton
                                    color="danger"
                                    onClick={() => {
                                        this.toggleAddForm(0)
                                    }}
                                    className="p-2 rounded-circle d-flex flex-column ml-auto mb-2 mr-2"
                                    >
                                        { this.state.collapses.includes(0) ? <CImg src={cross}/> : <CImg src={plus}/> }
                                </CButton>    
                            </CTooltip>
                        </CButtonToolbar>
                        <CCollapse show={this.state.collapses.includes(0)}>
                            <CCard className="domain__add p-2 border-0 m-0">
                                <CFormGroup row>
                                    <CCol md="6">
                                        <StringInput
                                            object={this.props.server}
                                            name="domain"
                                            propertyName="Домен"
                                            placeholder="Введите строку"
                                            type="text"
                                            row={true}
                                            columnSettings="3"
                                            columnClassName="text-left align-self-center"
                                        />
                                    </CCol>
                                    <CCol md="6">
                                        <CFormGroup row>   
                                            <CCol className="d-flex">
                                                <CButton 
                                                    type="button" 
                                                    size="sm" 
                                                    color="dark"
                                                    className="ml-auto" 
                                                    onClick={this.addDomain} 
                                                    disabled={this.props.server.domain === '' && true}
                                                >
                                                    Добавить
                                                </CButton>
                                            </CCol>
                                        </CFormGroup>
                                    </CCol>
                                </CFormGroup>
                            </CCard>
                        </CCollapse>
                        <CCardBody className="p-3 mt-0">
                            <CDataTable
                                items={copyItems}
                                fields={fields}  
                                noItemsViewSlot="Домены отсутствуют"
                                columnHeaderSlot={fieldsNames}
                                addTableClasses="mb-2 table__header"
                                pagination
                                itemsPerPage={5}
                                size="sm"
                                scopedSlots={{
                                    'name':
                                    (item: any) => (
                                        <td className="text-left">
                                            {item.name}
                                        </td>
                                    ),
                                    'action':
                                    (item: any, index: number) => (
                                        <td className="text-left">
                                            <CButton 
                                                type="button" 
                                                size="sm" 
                                                color="dark"
                                                variant="ghost"
                                                className="p-0" 
                                                onClick={() => {this.props.confirmDomain(index); this.setState({isConfirm: !this.state.isConfirm})}} 
                                            >
                                                {item.confirm ? 'Отменить': 'Подтвердить'}
                                            </CButton>
                                        </td>
                                    )
                                }}
                            />
                        </CCardBody>
                    </div>                    
                )
            }
} 

export default DomainsTab