import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import './scss/style.scss'
import Layout from './Ui/containers/Layout'
import UserLoginContainer from './Ui/containers/UserLoginContainer'
import { Provider } from 'mobx-react'
import { userStore } from './store/OrmUserStore'
import { serverStore } from './store/OrmServerStore'

const stores = {
  userStore,
  serverStore
}

class App extends Component {
  componentDidMount() {
    if (localStorage.getItem('isAuth') === 'true') {
      userStore.setValue(userStore.userLogin, 'isAuth', Boolean(localStorage.getItem('isAuth')))
    }
    if (localStorage.getItem('isRegistrationSuccess') === 'true') {
      userStore.setValue(userStore.userLogin, 'isRegistrationSuccess', Boolean(localStorage.getItem('isRegistrationSuccess')))
    }
    if (localStorage.getItem('isRefillingBalance') === 'true') {
      userStore.setValue(userStore.userBalance, 'isRefillingBalance', Boolean(localStorage.getItem('isRefillingBalance')))
    }
  }
  render() {
    return (
      <Provider {...stores}>
        <Switch>
          <Route path="/login" render={ () => <UserLoginContainer/>}/>
          <Route path="/" render={ () => <Layout/>}/>
        </Switch>
      </Provider>  
    )
  }
}

export default App;
