import { CCard, CCardBody, CCardHeader } from '@coreui/react'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { ConfiguratorType } from '../../store/OrmServerStore'
import TreeView from '@material-ui/lab/TreeView'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import _ from 'lodash'

interface PropsType extends RouteComponentProps {
    configurator: ConfiguratorType
    renderTree(nodes: any): any
}

@observer class ConfiguratorTab extends Component<PropsType> {
            render() {
                return (
                    <CCard className="d-flex search__tab__background border-0 h-100">
                        <CCardHeader className="search__tab__background border-0 p-0 d-flex mb-0">
                            <p className="mr-auto ml-2 header__text mb-2 p-2">CONFIGURATOR</p> 
                        </CCardHeader>
                        <CCardBody className="pt-0 search__input__column">   
                            <TreeView
                                defaultCollapseIcon={<ExpandMoreIcon />}
                                defaultExpandIcon={<ChevronRightIcon />}
                                multiSelect
                            >
                                {this.props.renderTree(_.cloneDeep(this.props.configurator.configuratorDemonstration))}
                            </TreeView>
                        </CCardBody>
                    </CCard>
                )
            }
} 

export default withRouter(ConfiguratorTab)