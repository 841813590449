import { userApi } from "../api/OrmUserApi"
import { userStore } from "../store/OrmUserStore"
import { billingBalanceController } from "./OrmBillingBalanceController"

class OrmUserController {
    login(loginData: {email: string, password: string}) {
        userApi.login(loginData).then( response => {
            if ( response.status === 200 ) {
                userStore.setValue(userStore.userLogin, 'isAuth', true)
                userStore.eraseData()
                localStorage.setItem('id', response.data.id)
                localStorage.setItem('userId', response.data.userId)
                localStorage.setItem('isAuth', String(userStore.userLogin.isAuth))
                window.close()
                window.open('/')
            } 
        }).catch( error => {
            userStore.setValue(userStore.userLogin, 'loginStatusCode', error.response.status)
        })
    }
    logout() {
        userApi.logout().then( response => {
            if ( response.status === 204 ) {
                userStore.setValue(userStore.userLogin, 'isAuth', false)
                localStorage.removeItem('id')
                localStorage.removeItem('isAuth')
            }
        }).catch( error => {
            console.log(error)
        })
    }
    getUser(id: string | null) {
        userApi.getUser(id).then( response => {
            if ( response.status === 200 ) {
                userStore.setValue(userStore.userLogin, 'user', response.data)
                billingBalanceController.getBillingBalancePoints()
            }
        } )
    }
    register(registrationData: any) {
        userApi.register(registrationData).then( response => {
            if ( response.status === 200 ) {
                window.close()
                window.open('/login/registration/success')
                userStore.setValue(userStore.userLogin, 'isRegistrationSuccess', true)
                localStorage.setItem('isRegistrationSuccess', String(userStore.userLogin.isRegistrationSuccess))
            }
        } )
    }
}

export const userController = new OrmUserController()

export default OrmUserController