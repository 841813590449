import { CCol, CFormGroup, CLabel, CSelect } from '@coreui/react'
import BaseSelect from './BaseSelect'

abstract class BaseSimpleSelect<T> extends BaseSelect<T> {

  render() {
    return (
      <CFormGroup row={this.props.row}>
          <CCol md="3" className="text-left align-self-center">
              <CLabel htmlFor={this.props.name}>{this.props.propertyName}</CLabel>
          </CCol>
          <CCol xs="12" md="12">
              <CSelect 
                name={this.props.name} 
                id={this.props.name} 
                onChange={this.handleChange}
                size={this.props.size}
                className={this.props.inputClassName}
              >
                <option value={this.renderValue(this.getValue())}>{this.renderValue(this.getValue())}</option>
                { this.props.modelsList.map((item: any, index: number) => {
                    return <option 
                              key={index} 
                            >
                              {item}
                            </option>
                }) }
              </CSelect>
          </CCol>
      </CFormGroup>
    )
  }
}

export default BaseSimpleSelect
