import { CBreadcrumb, CBreadcrumbItem, CButtonToolbar, CCardHeader, CNavLink } from "@coreui/react"
import { observer } from "mobx-react"
import React, { Component } from "react"
import EditButton from "./EditButton"

interface PropsType {
    name: string
    propertyName: string
    toggleForm(): void
}

@observer class ControlPanel extends Component<PropsType> {
            render() {
                const props = {
                    name: this.props.name,
                    toggleForm: this.props.toggleForm
                }
                return (
                    <div>
                        <CCardHeader className="d-flex align-items-start border-0 mb-0">
                            <CBreadcrumb className="mb-0 border-0">
                                <CBreadcrumbItem className="p-2">
                                    <CNavLink className="p-0" disabled>{this.props.propertyName}</CNavLink>
                                </CBreadcrumbItem>
                            </CBreadcrumb>
                            <CButtonToolbar className="ml-auto">
                                <EditButton {...props}/>
                            </CButtonToolbar>
                        </CCardHeader>
                    </div>
                )
            }
}

export default ControlPanel