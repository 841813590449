import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import DataTable from '../UiComponents/Table/DataTable'
import { UseLocation } from '../../support/useLocationHoC'
import { MatchType } from '../containers/ServersContainer'
import { ServersType } from '../../store/OrmServerStore'
import CIcon from '@coreui/icons-react'
import { CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react'


interface PropsType extends RouteComponentProps {
    match: MatchType
    servers: ServersType
    query: any
    loadPage(elementId: string, url: string, page: string): void
    changeCurrentPage(page: number): void
    toggleForm(): void
}

@observer class Servers extends Component<PropsType> {
            componentDidMount() {
                const query = this.props.query
                const page = query.get('page') as any
                this.props.loadPage(this.props.match.params.elementId, this.props.match.url, page)
            }
            render() {
                return ( 
                    <DataTable
                        models={this.props.servers}
                        changeCurrentPage={this.props.changeCurrentPage}
                        toggleForm={this.props.toggleForm}
                        name="servers"
                        propertyName="Серверы"
                        fields={['icon', 'name', 'action']}
                        names={{'icon': ' ', 'name': 'Наименование', 'action': 'Параметры'}}
                        scopedSlots={{
                            'icon':
                            (item: any) => (
                                <td>
                                    <CIcon name="cil-laptop" size="lg"/>
                                </td>
                            ),
                            'name':
                            (item: any) => (
                                <td className="text-left">
                                    {item.name}
                                </td>
                            ),
                            'action':
                            (item: any, index: number) => (
                                <td className="text-left">
                                    <CDropdown className="mr-2">
                                        <CDropdownToggle color="dark" variant="ghost" size="sm" caret={false} className="p-0">
                                            Открыть
                                        </CDropdownToggle> 
                                        <CDropdownMenu>
                                            <CDropdownItem 
                                                onClick={() => {
                                                    this.props.toggleForm(); 
                                                    this.props.history.push("/servers/" + index)
                                                }} 
                                            >
                                                Параметры сервера
                                            </CDropdownItem>
                                            <CDropdownItem divider />
                                            <CDropdownItem 
                                                to={'/configurator'}
                                            >
                                                Конфигуратор
                                            </CDropdownItem>
                                        </CDropdownMenu>
                                    </CDropdown>
                                </td>
                            )
                        }}
                    />
                )
            }
}

export default UseLocation(withRouter(Servers))