import { CButton, CModal, CModalBody, CModalFooter } from '@coreui/react'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { MatchType } from '../containers/UserLoginContainer'

interface PropsType extends RouteComponentProps {
    match: MatchType
    isModalOpen: boolean
    toggleModal(): void
    loadPage(url: string): void
    changeRegistrationSuccessFlag(): void
}

@observer class RegistrationSuccess extends Component<PropsType> {
            componentDidMount() {
                this.props.loadPage(this.props.match.url)
            }
            render() { 
                return (
                    <CModal
                        centered
                        show={this.props.isModalOpen}
                        onClose={() => {
                            this.props.toggleModal();
                            this.props.history.push("/login")
                        }}
                        size="sm"
                    >
                        <CModalBody>
                            Регистрация прошла успешно!
                        </CModalBody>
                        <CModalFooter className="border-0">
                            <CButton 
                                type="button" 
                                size="lg" 
                                color="danger"
                                className="w-100"
                                to="/login" 
                                onClick={() => {
                                    this.props.toggleModal();
                                    this.props.changeRegistrationSuccessFlag();
                                    localStorage.removeItem('isRegistrationSuccess')
                                }}
                            >
                                Закрыть
                            </CButton>
                        </CModalFooter>
                    </CModal>
                )
            }
} 

export default withRouter(RegistrationSuccess)