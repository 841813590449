import { CButton, CButtonClose, CModal, CModalBody, CModalFooter, CModalHeader, CNav, CNavItem } from '@coreui/react'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { UserBalanceType } from '../../store/OrmUserStore'
import FloatNumberInput from '../ChangeFormInputs/FloatNumberInput'
import { MatchType } from '../containers/UserLoginContainer'

interface PropsType extends RouteComponentProps {
    match: MatchType
    isModalOpen: boolean
    userBalance: UserBalanceType
    toggleModal(status: boolean): void
    loadPage(url: string): void
    refillBalance(amount: number): void
}

@observer class Refill extends Component<PropsType> {
            componentDidMount() {
                document.title = 'Пополнение баланса'
                this.props.loadPage(this.props.match.url)
            }
            render() { 
                return (
                    <CModal
                        centered
                        show={this.props.isModalOpen}
                        onClose={() => {
                            this.props.toggleModal(!this.props.isModalOpen);
                            this.props.history.push("/");
                            document.title = 'Личный кабинет НАТИВИ'
                        }}
                        size="sm"
                    >
                        <CModalHeader className="border-0 font-weight-bold pb-0">
                            Пополнение баланса
                            <CNav variant="tabs" className="mb-4 border-0">
                                <CNavItem className="ml-auto">
                                    <CButtonClose 
                                        onClick={() => {
                                            this.props.toggleModal(!this.props.isModalOpen);
                                            this.props.history.push("/");
                                            document.title = 'Личный кабинет НАТИВИ'
                                    }}/>
                                </CNavItem>
                            </CNav>
                        </CModalHeader>
                        <CModalBody>
                            <FloatNumberInput
                                object={this.props.userBalance}
                                name="amount"
                                propertyName="Сумма"
                                placeholder="Введите сумму"
                                type="number"
                                columnSettings="3"
                                columnClassName="text-left align-self-center"
                                inputClassName="text-right"
                                row={true}
                            />
                        </CModalBody>
                        <CModalFooter className="border-0">
                            <CButton 
                                type="button" 
                                size="lg" 
                                color="danger"
                                className="w-100"
                                to="/" 
                                onClick={() => {
                                    this.props.toggleModal(!this.props.isModalOpen);
                                    this.props.refillBalance(this.props.userBalance.amount)
                                }}
                            >
                                Пополнить
                            </CButton>
                        </CModalFooter>
                    </CModal>
                )
            }
} 

export default withRouter(Refill)