import { CFormGroup, CInput, CInputGroup, CInputGroupPrepend, CInputGroupText, CInvalidFeedback } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import BaseInput from './BaseInput'

abstract class BaseSimpleLoginInput<T> extends BaseInput<T> {

  render() {
    return (
      <CFormGroup className="d-flex justify-content-center">
          <CInputGroup className="mb-3">
              <CInputGroupPrepend>
                  <CInputGroupText>
                      <CIcon name={this.props.iconName} size={this.props.size}/>
                  </CInputGroupText>
              </CInputGroupPrepend>
              <CInput 
                  invalid={this.props.invalid && (this.props.object[this.props.name] !== this.props.object.password ? true: false)}
                  type={this.props.type} 
                  id={this.props.name} 
                  name={this.props.name} 
                  placeholder={this.props.placeholder}
                  autoComplete={this.props.autoComplete}  
                  onChange={this.handleChange} 
                  value={this.renderValue(this.getValue())}
                  size={this.props.size}
              />
              { this.props.object[this.props.name] !== this.props.object.password && <CInvalidFeedback>Пароли не совпадают!</CInvalidFeedback> }
          </CInputGroup>
      </CFormGroup>
    )
  }
}

export default BaseSimpleLoginInput
