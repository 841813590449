import CIcon from '@coreui/icons-react'
import { CButton, CCard, CCardBody, CCardHeader, CNav, CNavItem, CNavLink, CSidebar, CSidebarNav, CTabContent, CTabPane, CTabs, CTooltip } from '@coreui/react'
import { TreeItem } from '@material-ui/lab'
import _ from 'lodash'
import { runInAction } from 'mobx'
import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { OrmServerStoreType } from '../../store/OrmServerStore'
import ConfiguratorTab from './ConfiguratorTab'
import PropertyChangeForm from './PropertyChangeForm'
import Schema from './Schema'
import SearchTab from './SearchTab'


export interface MatchType {
    isExact: boolean
    params: {elementId: string}
    path:string
    url: string
}

interface PropsType extends RouteComponentProps {
    
}

interface InjectedProps extends PropsType{
    serverStore: OrmServerStoreType
}

interface StateType {
    firstWindowTabArray: Array<any>
    secondWindowTabArray: Array<any>
    firstWindowActiveTab: number
    secondWindowActiveTab: number
}

@inject('serverStore')
@observer class Configurator extends Component<PropsType, StateType> {
    constructor(props: PropsType) {
        super(props)
        this.state = {
            firstWindowTabArray: [],
            secondWindowTabArray: [],
            firstWindowActiveTab: 0,
            secondWindowActiveTab: 0
        }
        this.loadPage = this.loadPage.bind(this)
        this.createTab = this.createTab.bind(this)
        this.deleteTab = this.deleteTab.bind(this)
        this.updateData = this.updateData.bind(this)
        this.renderTree = this.renderTree.bind(this)
        this.editProperty = this.editProperty.bind(this)
    }

    componentDidMount() {
        this.loadPage(this.props.match.url)
    }

    get injected() {
        return this.props as InjectedProps
    }

    loadPage(url: string) {
        if ( url === '/configurator' ) {
            document.title = 'Личный кабинет НАТИВИ: Конфигуратор'
        } 
    }

    createTab(item: any, window: string, index?: number) {
        const tabArray = []
        tabArray.push(item)
        if ( window === 'first' ) {
            runInAction(() => {
                this.injected.serverStore.configurator.editModels.push({item, index})
            })
            if ( this.state.firstWindowTabArray.length < 5 ) {
                this.setState({
                    firstWindowTabArray: this.state.firstWindowTabArray.concat(tabArray)
                })
            }
        } else if ( window === 'second' ) {
            if ( this.state.secondWindowTabArray.length < 5 ) {
                this.setState({
                    secondWindowTabArray: this.state.secondWindowTabArray.concat(tabArray)
                })  
            }
        }
    }

    deleteTab(index: number, window: string) {
        if ( window === 'first' ) {
            const tabArray = this.state.firstWindowTabArray
            tabArray.splice(index, 1)
            this.setState({
                firstWindowTabArray: tabArray
            })
            runInAction(() => {
                this.injected.serverStore.configurator.editModels.splice(index, 1)
            })
        } else if ( window === 'second' ) {
            const tabArray = this.state.secondWindowTabArray
            tabArray.splice(index, 1)
            this.setState({
                secondWindowTabArray: tabArray
            })
        }
    }

    updateData(fieldName: string, item: any, index: number) {
        const tabArray = this.state.firstWindowTabArray
        tabArray[index][fieldName] = item
        if ( tabArray[index].type !== 'relation' )  {
            delete tabArray[index].relationModel
            runInAction(() => {
                this.injected.serverStore.configurator.editModels[index].item = tabArray[index]
            })
        } else {
            tabArray[index][fieldName] = item
        }
        this.setState({
            firstWindowTabArray: tabArray
        })
    }

    editProperty(value: any) {
        const dataTree = this.injected.serverStore.configurator.configuratorDemonstration
        dataTree.forEach((item: any) => {
            item.childList.forEach((itm: any) => {
                if ( itm.modelName === value.item.owner && value.item.fromObjectItemList === undefined ) {
                    runInAction(() => {
                        itm.propertyList.splice(value.index, 1, value.item)
                    })
                } else if ( itm.modelName === value.item.owner && value.item.fromObjectItemList ) {
                    itm.objectItemList.forEach((i: any) => {
                        if ( i.name === value.item.parent ) {
                            runInAction(() => {
                                i.propertyList.splice(value.index, 1, value.item)
                            })
                        }
                    })
                }
            })
        })
    }

    renderTree(nodes: any, ind?: number) {
        return nodes.map((item: any, index: number) => {
            return  <TreeItem 
                        key={index} 
                        nodeId={String(Math.random() * 10000000000000000)} 
                        label={item.name} 
                        classes={{label: 'text'}}
                        icon={(!_.hasIn(item, 'propertyList') && !_.hasIn(item, 'objectItemList') && !_.hasIn(item, 'methodList') && !_.hasIn(item, 'childList')) && ' ' }
                        onLabelClick={
                            !_.hasIn(item, 'propertyList') && !_.hasIn(item, 'objectItemList') && !_.hasIn(item, 'methodList') && !_.hasIn(item, 'childList') && item.version === undefined ?
                            () => this.createTab(item, 'first', ind) :
                            () => ''
                        }
                        onIconClick={
                            !_.hasIn(item, 'propertyList') && !_.hasIn(item, 'objectItemList') && !_.hasIn(item, 'methodList') && !_.hasIn(item, 'childList') && item.version === undefined ?
                            () => this.createTab(item, 'first', ind) :
                            () => ''
                        }
                    >
                        {
                            item.childList !== undefined && 
                            item.childList.map((node: any, ind: number) => this.renderTree([node], ind))
                        }
                        {
                            item.propertyList !== undefined && 
                            <TreeItem label="Свойства" nodeId={String(Math.random() * 10000000000000000)} classes={{label: 'text'}}>{item.propertyList.map((node: any, ind: number) => this.renderTree([node], ind))}</TreeItem> 
                        }
                        {
                            item.objectItemList !== undefined && 
                            <TreeItem label="Списки" nodeId={String(Math.random() * 10000000000000000)} classes={{label: 'text'}}>{item.objectItemList.map((node: any, ind: number) => this.renderTree([node], ind))}</TreeItem> 
                        }
                        {
                            item.methodList !== undefined && 
                            <TreeItem label="Методы" nodeId={String(Math.random() * 10000000000000000)} classes={{label: 'text'}}>{item.methodList.map((node: any, ind: number) => this.renderTree([node], ind))}</TreeItem>
                        }
                    </TreeItem>
        })
    }
    render() { 
        const props = {
            configurator: this.injected.serverStore.configurator,
            renderTree: this.renderTree
        }
        return (
            <CCard className="m-3 d-flex configurator__background flex-row border-0 ">
                <CTabs>
                    <CSidebar minimize={true} fixed={false} unfoldable={true} className="configurator__nav__sidebar">
                        <CNav className="configurator__sidebar__link border-0" >
                            <CTooltip content="Конфигуратор" placement="right-end">
                                <CNavItem>
                                    <CNavLink>
                                        <CIcon name="cil-copy" size="lg" className="m-2"/>
                                    </CNavLink>
                                </CNavItem>
                            </CTooltip>
                            <CTooltip content="Поиск" placement="right-end">
                                <CNavItem>
                                    <CNavLink>
                                        <CIcon name="cil-search" size="lg" className="m-2"/>
                                    </CNavLink>
                                </CNavItem>
                            </CTooltip>
                            <CTooltip content="Контроль версий" placement="right-end">
                                <CNavItem>
                                    <CNavLink>
                                        <CIcon name="cil-fork" size="lg" className="m-2"/>
                                    </CNavLink>
                                </CNavItem>
                            </CTooltip>
                            <CTooltip content="Отладчик" placement="right-end">
                                <CNavItem>
                                    <CNavLink>
                                        <CIcon name="cil-media-play" size="lg" className="m-2"/>
                                    </CNavLink>
                                </CNavItem>
                            </CTooltip>
                            <CTooltip content="Развертывание" placement="right-end">
                                <CNavItem>
                                    <CNavLink>
                                        <CIcon name="cil-cloud" size="lg" className="m-2"/>
                                    </CNavLink>
                                </CNavItem>
                            </CTooltip>
                        </CNav>
                    </CSidebar> 
                    <CSidebar fixed={false} unfoldable={true} className="sidebar__content__window">
                    <CSidebarNav>
                        <CTabContent>
                                <CTabPane>
                                    <ConfiguratorTab {...props}/>
                                </CTabPane>
                                <CTabPane>
                                    <SearchTab {...props}/>
                                </CTabPane>
                                <CTabPane>
                                    <CCard className="d-flex search__tab__background border-0 h-100">
                                        <CCardHeader className="search__tab__background border-0 p-0 d-flex mb-0">
                                            <p className="mr-auto ml-2 header__text mb-2 p-2">SOURCE CONTROL</p> 
                                        </CCardHeader>
                                    </CCard>
                                </CTabPane>
                                <CTabPane>
                                    <CCard className="d-flex search__tab__background border-0 h-100">
                                        <CCardHeader className="search__tab__background border-0 p-0 d-flex mb-2">
                                            <p className="mr-auto ml-2 header__text mb-2 ">RUN AND DEBUG: RUN</p> 
                                        </CCardHeader>
                                        <CCardBody className="pt-0 debug__button">
                                            <CButton color="info" className="rounded-0 w-100" size="sm">Run and Debug</CButton>
                                        </CCardBody>
                                    </CCard>
                                </CTabPane>
                                <CTabPane>
                                    <CCardHeader className="search__tab__background border-0 p-0 d-flex mb-0">
                                        <p className="mr-auto ml-2 header__text mb-2 p-2">DEPLOYMENT</p> 
                                    </CCardHeader>
                                </CTabPane>
                            </CTabContent>
                            </CSidebarNav>
                    </CSidebar>
                </CTabs>
                <CCardBody className="text-white p-0 configurator__main__window d-flex">
                    <CCardBody className="configurator__main__window__first p-0 m-0">
                        {this.state.firstWindowTabArray.length !== 0 &&     <CTabs onActiveTabChange={(activeTab: number)=> this.setState({firstWindowActiveTab: activeTab})}>
                                                                                <CNav variant="tabs" className="first__window__tab__panel">
                                                                                    {this.state.firstWindowTabArray.map((item: any, index: number) => {
                                                                                        return  <CNavItem key={index}>
                                                                                                    <CNavLink className="rounded-0 p-2">
                                                                                                        {item.name} {item.schema !== undefined && '- ' + item.apiPath}  <CButton 
                                                                                                                                                                            onClick={() => 
                                                                                                                                                                                this.deleteTab(index, 'first')
                                                                                                                                                                            } 
                                                                                                                                                                            className={
                                                                                                                                                                                this.state.firstWindowActiveTab === index ? 
                                                                                                                                                                                'p-0 ml-1 mb-1 active__tab' : 
                                                                                                                                                                                'p-0 ml-1 mb-1 tab__close__button'
                                                                                                                                                                            }
                                                                                                                                                                        >
                                                                                                                                                                            <CIcon 
                                                                                                                                                                                name="cil-x" 
                                                                                                                                                                                size="sm" 
                                                                                                                                                                                className="m-1"
                                                                                                                                                                            />
                                                                                                                                                                        </CButton>
                                                                                                    </CNavLink>
                                                                                                </CNavItem>     
                                                                                    })}
                                                                                </CNav>
                                                                                <CTabContent>
                                                                                    {this.state.firstWindowTabArray.map((item: any, index: number) => {
                                                                                        return  <CTabPane key={index}>
                                                                                                    {
                                                                                                        item.schema !== undefined ?    
                                                                                                        <Schema 
                                                                                                            schema={item.schema}
                                                                                                            createTab={this.createTab}
                                                                                                        /> :
                                                                                                        <PropertyChangeForm 
                                                                                                            editableItem={this.injected.serverStore.configurator.editModels}
                                                                                                            index={index}
                                                                                                            editProperty={this.editProperty}
                                                                                                            updateData={this.updateData}
                                                                                                            typesList={this.injected.serverStore.configurator.types}
                                                                                                            modelsList={this.injected.serverStore.configurator.models}
                                                                                                        />
                                                                                                    }
                                                                                                </CTabPane>     
                                                                                    })}
                                                                                </CTabContent>                            
                                                                            </CTabs>
                        }   
                    </CCardBody>
                    <CCardBody className="configurator__main__window__second p-0 m-0">
                        {this.state.secondWindowTabArray.length !== 0 &&    <CTabs onActiveTabChange={(activeTab: number)=> this.setState({secondWindowActiveTab: activeTab})}>
                                                                                <CNav variant="tabs">
                                                                                    {this.state.secondWindowTabArray.map((item: any, index: number) => {
                                                                                        return  <CNavItem key={index} >
                                                                                                    <CNavLink className="rounded-0 p-2">
                                                                                                        {item.code.method}  <CButton 
                                                                                                                                onClick={() => 
                                                                                                                                    this.deleteTab(index, 'second')
                                                                                                                                } 
                                                                                                                                className={
                                                                                                                                    this.state.secondWindowActiveTab === index ? 
                                                                                                                                    'p-0 ml-1 mb-1 active__tab' : 
                                                                                                                                    'p-0 ml-1 mb-1 tab__close__button'
                                                                                                                                }
                                                                                                                            >
                                                                                                                                <CIcon 
                                                                                                                                    name="cil-x" 
                                                                                                                                    size="sm" 
                                                                                                                                    className="m-1"
                                                                                                                                />
                                                                                                                            </CButton>
                                                                                                    </CNavLink>
                                                                                                </CNavItem>     
                                                                                    })}
                                                                                </CNav>
                                                                                <CTabContent>
                                                                                    {this.state.secondWindowTabArray.map((item: any, index: number) => {
                                                                                        return  <CTabPane key={index} className="text-nowrap p-2">
                                                                                                    {item.code.code}
                                                                                                </CTabPane>     
                                                                                    })}
                                                                                </CTabContent>                            
                                                                            </CTabs>
                        }  
                    </CCardBody>
                </CCardBody>
            </CCard>
        )
    }
} 

export default withRouter(Configurator)