import { observable, action, makeObservable, set } from "mobx"

export interface ServerType {
    name: string
    description: string
    solution: any
    cores: number,
    RAM: number,
    nodes: number
    domain?: string
    domains: Array<any>
}

export interface ServersType {
    list: Array<any>
    itemsPerPage: number
    currentPage: number
    count: number
    solutions: Array<string>
}

export interface ConfiguratorType {
    editableItem: any
    editModels: Array<any>
    search: string
    types: Array<string>
    models: Array<string>
    searchDemonstration: Array<any>
    configuratorDemonstration: Array<any>
}

export interface OrmServerStoreType {
    servers: ServersType
    server: ServerType
    configurator: ConfiguratorType
    setValue(model: any, key: string, value: any): void
    addDomain(domain: any): void
    confirmDomain(index: number): void
    resetFormData(): void
}

class OrmServerStore implements OrmServerStoreType {
    constructor() {
        makeObservable(this, {
            servers: observable,
            server: observable,
            configurator: observable,
            setValue: action,
            addDomain: action,
            confirmDomain: action,
            resetFormData: action
        })
    }
    servers = {
        list: [],
        itemsPerPage: 10,
        currentPage: 0,
        count: 0,
        solutions: ['Нативи Торговля', 'Пустое решение']
    }

    server = {
        name: '',
        description: '',
        solution: '',
        cores: 2,
        RAM: 1,
        nodes: 1,
        domain: '',
        domains: [] as Array<any>
    }

    configurator = {
        editableItem: {},
        editModels: [],
        search: 'Справочники',
        types: ['string', 'number', 'date', 'boolean', 'relation'],
        models: ['Product', 'ProductUnit', 'Organization', 'Warehouse', 'Client', 'PriceType'],
        searchDemonstration: [
            {
              "name": "Справочники",
              "childList": [
                {
                  "name": "Товары",
                  "modelName": "Product",
                  "propertyList": [
                    {
                      "name": "Идентификатор",
                      "type": "string"
                    },
                    {
                      "name": "Удален",
                      "type": "boolean"
                    },
                    {
                      "name": "Наименование",
                      "type": "string"
                    },
                    {
                      "name": "Единица измерения",
                      "type": "relation",
                      "relationModel": "ProductUnit"
                    }
                  ],
                  "objectItemList": [],
                  "methodList": []
                },
                {
                  "name": "Организации",
                  "modelName": "Organization",
                  "propertyList": [
                    {
                      "name": "Идентификатор",
                      "type": "string"
                    },
                    {
                      "name": "Удален",
                      "type": "boolean"
                    },
                    {
                      "name": "Наименование",
                      "type": "string"
                    }
                  ],
                  "objectItemList": [],
                  "methodList": []
                },
                {
                  "name": "Склады",
                  "modelName": "Warehouse",
                  "propertyList": [
                    {
                      "name": "Идентификатор",
                      "type": "string"
                    },
                    {
                      "name": "Удален",
                      "type": "boolean"
                    },
                    {
                      "name": "Наименование",
                      "type": "string"
                    }
                  ],
                  "objectItemList": [],
                  "methodList": []
                },
                {
                  "name": "Клиенты",
                  "modelName": "Client",
                  "propertyList": [
                    {
                      "name": "Идентификатор",
                      "type": "string"
                    },
                    {
                      "name": "Удален",
                      "type": "boolean"
                    },
                    {
                      "name": "Наименование",
                      "type": "string"
                    }
                  ],
                  "objectItemList": [],
                  "methodList": []
                },
                {
                  "name": "Единицы измерения",
                  "modelName": "ProductUnit",
                  "propertyList": [
                    {
                      "name": "Идентификатор",
                      "type": "string"
                    },
                    {
                      "name": "Удален",
                      "type": "boolean"
                    },
                    {
                      "name": "Наименование",
                      "type": "string"
                    }
                  ],
                  "objectItemList": [],
                  "methodList": []
                },
                {
                  "name": "Типы цен",
                  "modelName": "PriceType",
                  "propertyList": [
                    {
                      "name": "Идентификатор",
                      "type": "string"
                    },
                    {
                      "name": "Удален",
                      "type": "boolean"
                    },
                    {
                      "name": "Наименование",
                      "type": "string"
                    }
                  ],
                  "objectItemList": [],
                  "methodList": []
                }
              ]
            }
        ],
        configuratorDemonstration: [
          {
            "name": "Справочники",
            "childList": [
              {
                "name": "Товары",
                "modelName": "Product",
                "propertyList": [
                  {
                    "name": "Идентификатор",
                    "type": "string",
                    "owner": "Product"
                  },
                  {
                    "name": "Удален",
                    "type": "boolean",
                    "owner": "Product"
                  },
                  {
                    "name": "Наименование",
                    "type": "string",
                    "owner": "Product"
                  },
                  {
                    "name": "Единица измерения",
                    "type": "relation",
                    "relationModel": "ProductUnit",
                    "owner": "Product"
                  }
                ],
                "objectItemList": [],
                "methodList": []
              },
              {
                "name": "Организации",
                "modelName": "Organization",
                "propertyList": [
                  {
                    "name": "Идентификатор",
                    "type": "string",
                    "owner": "Organization"
                  },
                  {
                    "name": "Удален",
                    "type": "boolean",
                    "owner": "Organization"
                  },
                  {
                    "name": "Наименование",
                    "type": "string",
                    "owner": "Organization"
                  }
                ],
                "objectItemList": [],
                "methodList": []
              },
              {
                "name": "Склады",
                "modelName": "Warehouse",
                "propertyList": [
                  {
                    "name": "Идентификатор",
                    "type": "string",
                    "owner": "Warehouse"
                  },
                  {
                    "name": "Удален",
                    "type": "boolean",
                    "owner": "Warehouse"
                  },
                  {
                    "name": "Наименование",
                    "type": "string",
                    "owner": "Warehouse"
                  }
                ],
                "objectItemList": [],
                "methodList": []
              },
              {
                "name": "Клиенты",
                "modelName": "Client",
                "propertyList": [
                  {
                    "name": "Идентификатор",
                    "type": "string",
                    "owner": "Client"
                  },
                  {
                    "name": "Удален",
                    "type": "boolean",
                    "owner": "Client"
                  },
                  {
                    "name": "Наименование",
                    "type": "string",
                    "owner": "Client"
                  }
                ],
                "objectItemList": [],
                "methodList": []
              },
              {
                "name": "Единицы измерения",
                "modelName": "ProductUnit",
                "propertyList": [
                  {
                    "name": "Идентификатор",
                    "type": "string",
                    "owner": "ProductUnit"
                  },
                  {
                    "name": "Удален",
                    "type": "boolean",
                    "owner": "ProductUnit"
                  },
                  {
                    "name": "Наименование",
                    "type": "string",
                    "owner": "ProductUnit"
                  }
                ],
                "objectItemList": [],
                "methodList": []
              },
              {
                "name": "Типы цен",
                "modelName": "PriceType",
                "propertyList": [
                  {
                    "name": "Идентификатор",
                    "type": "string",
                    "owner": "PriceType"
                  },
                  {
                    "name": "Удален",
                    "type": "boolean",
                    "owner": "PriceType"
                  },
                  {
                    "name": "Наименование",
                    "type": "string",
                    "owner": "PriceType"
                  }
                ],
                "objectItemList": [],
                "methodList": []
              }
            ]
          },
          {
            "name": "Документы",
            "childList": [
              {
                "name": "Заказы покупателей",
                "modelName": "CustomerOrder",
                "propertyList": [
                  {
                    "name": "Идентификатор",
                    "type": "string",
                    "owner": "CustomerOrder"
                  },
                  {
                    "name": "Удален",
                    "type": "boolean",
                    "owner": "CustomerOrder"
                  },
                  {
                    "name": "Код",
                    "type": "string",
                    "owner": "CustomerOrder"
                  },
                  {
                    "name": "Дата",
                    "type": "date",
                    "owner": "CustomerOrder"
                  },
                  {
                    "name": "Организация",
                    "type": "relation",
                    "relationModel": "Organization",
                    "owner": "CustomerOrder"
                  },
                  {
                    "name": "Клиент",
                    "type": "relation",
                    "relationModel": "Client",
                    "owner": "CustomerOrder"
                  },
                  {
                    "name": "Склад",
                    "type": "relation",
                    "relationModel": "Warehouse",
                    "owner": "CustomerOrder"
                  },
                  {
                    "name": "Тип цены",
                    "type": "relation",
                    "relationModel": "PriceType",
                    "owner": "CustomerOrder"
                  },
                  {
                    "name": "Сумма",
                    "type": "number",
                    "owner": "CustomerOrder"
                  }
                ],
                "objectItemList": [
                  {
                    "name": "Товары",
                    "propertyList": [
                      {
                        "name": "Товар",
                        "type": "relation",
                        "relationModel": "Product",
                        "owner": "CustomerOrder",
                        "parent": "Товары",
                        "fromObjectItemList": true
                      },
                      {
                        "name": "Единица измерения",
                        "type": "relation",
                        "relationModel": "ProductUnit",
                        "owner": "CustomerOrder",
                        "parent": "Товары",
                        "fromObjectItemList": true
                      },
                      {
                        "name": "Количество",
                        "type": "number",
                        "owner": "CustomerOrder",
                        "parent": "Товары",
                        "fromObjectItemList": true
                      },
                      {
                        "name": "Цена",
                        "type": "number",
                        "owner": "CustomerOrder",
                        "parent": "Товары",
                        "fromObjectItemList": true
                      },
                      {
                        "name": "Сумма",
                        "type": "number",
                        "owner": "CustomerOrder",
                        "parent": "Товары",
                        "fromObjectItemList": true
                      }
                    ]
                  }
                ],
                "methodList": [
                  {
                    "name": "Обработка заказа",
                    "apiPath": "process",
                    "schema": {
                      modelName: 'Заказ покупателя',
                      testButton: [
                        {
                          name: 'Тест 1'
                        },
                        {
                          name: 'Тест 2'
                        },
                        {
                          name: 'Тест 3',
                        }
                      ],
                      phases: [
                        {
                          name: 'Создание заказа покупателя и его проведение',
                          code: {
                            method: 'process',
                            code: 'Некоторый псевдокод описывающий этап - Создание заказа покупателя и его проведение'
                          },
                          in: 'Заказ покупателя',
                          out: 'Заказ покупателя',
                        },
                        {
                          name: 'Расчет доставки',
                          code: {
                            method: 'process',
                            code: 'Некоторый псевдокод описывающий этап - Расчет доставки'
                          },
                          in: 'Заказ покупателя',
                          out: 'Заказ покупателя',
                        },
                        {
                          name: 'Рассылка почты',
                          code: {
                            method: 'process',
                            code: 'Некоторый псевдокод описывающий этап - Рассылка почты'
                          },
                          in: 'Заказ покупателя',
                          out: 'Заказ покупателя',
                        },
                        {
                          name: 'return',
                          code: {
                            method: 'process',
                            code: 'Некоторый псевдокод описывающий этап - return'
                          },
                          in: 'Заказ покупателя',
                          out: 'Заказ покупателя',
                        }
                      ]
                    }
                  }
                ]
              }
            ]
          },
          {
            "name": "Регистры накопления",
            "childList": [
              {
                "name": "Товары на складах",
                "modelName": "ProductInStock",
                "propertyList": [
                  {
                    "name": "Идентификатор",
                    "type": "string",
                    "owner": "ProductInStock"
                  },
                  {
                    "name": "Товар",
                    "type": "relation",
                    "relationModel": "Warehouse",
                    "owner": "ProductInStock"
                  },
                  {
                    "name": "Склад",
                    "type": "relation",
                    "relationModel": "Warehouse",
                    "owner": "ProductInStock"
                  },
                  {
                    "name": "В наличии",
                    "type": "number",
                    "owner": "ProductInStock"
                  }
                ],
                "objectItemList": [],
                "methodList": []
              }
            ]
          },
          {
            "name": "Регистры информации",
            "childList": [
              {
                "name": "Цены товаров",
                "modelName": "ProductPrice",
                "propertyList": [
                  {
                    "name": "Идентификатор",
                    "type": "string",
                    "owner": "ProductPrice"
                  },
                  {
                    "name": "Товар",
                    "type": "relation",
                    "relationModel": "Warehouse",
                    "owner": "ProductPrice"
                  },
                  {
                    "name": "Тип цены",
                    "type": "relation",
                    "relationModel": "PriceType",
                    "owner": "ProductPrice"
                  },
                  {
                    "name": "Цена",
                    "type": "number",
                    "owner": "ProductPrice"
                  }
                ],
                "objectItemList": [],
                "methodList": []
              }
            ]
          },
          {
            "name": "Зависимости",
            "childList": [
              {
                "name": "library 1",
                "version": "1.0.0"
              },
              {
                "name": "library 2",
                "version": "2.0.1"
              }
            ]
          }
        ]
    }

    setValue(model: any, key: string, value: any) {
        set(model, key, value)
    }
    addDomain(domain: any) {
        this.server.domains.push(domain)
    }
    confirmDomain(index: number) {
        this.server.domains[index].confirm = !this.server.domains[index].confirm
    }
    resetFormData() {
        this.server = {
            name: '',
            description: '',
            solution: '',
            cores: 2,
            RAM: 1,
            nodes: 1,
            domain: '',
            domains: [] as Array<any>
        }
    }
}

export const serverStore = new OrmServerStore()

export default OrmServerStore
