import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import _ from 'lodash'
import { ServersType, ServerType } from '../../store/OrmServerStore'
import { UseLocation } from '../../support/useLocationHoC'
import { MatchType } from '../containers/ServersContainer'
import StringInput from '../ChangeFormInputs/StringInput'
import StringSelect from '../ChangeFormInputs/StringSelect'
import NumberInput from '../ChangeFormInputs/NumberInput'
import { CButton, CButtonClose, CModal, CModalBody, CModalFooter, CNav, CNavItem, CNavLink, CTabContent, CTabPane, CTabs } from '@coreui/react'
import Inputs from '../UiComponents/Form/Inputs'
import DomainsTab from './DomainsTab'

interface PropsType extends RouteComponentProps {
    match: MatchType
    isFormOpen: boolean
    server: ServerType
    servers: ServersType
    query: any
    search: any
    loadPage(elementId: string, url: string, page?: string): void
    toggleForm(): void
    addDomain(domain: any): void
    confirmDomain(index: number): void
    saveData(data: any): void
    editServer(data: any, index: number): void
    resetFormData(): void
}

interface StateType {
    error: string
}

@observer class ServersChangeForm extends Component<PropsType, StateType> {
            constructor(props: PropsType) {
                super(props)
                this.state = {
                    error: ''
                }
                this.submit = this.submit.bind(this)
                this.disableSubmit = this.disableSubmit.bind(this)
            }
            componentDidMount() {
                const query = this.props.query
                const page = query.get('page') as any
                this.props.loadPage(this.props.match.params.elementId, this.props.match.url, page)
            }

            disableSubmit(error: string) {
                this.setState({
                    error
                })
            }

            submit() { 
                let server = _.cloneDeep(this.props.server)
                delete server.domain
                if ( this.props.match.url === '/servers/new' ) {
                    this.props.saveData(server)
                } else {
                    this.props.editServer(server, Number(this.props.match.params.elementId))
                }
            }
            render() { 
                const props = {
                    server: this.props.server,
                    servers: this.props.servers,
                    addDomain: this.props.addDomain,
                    confirmDomain: this.props.confirmDomain
                }
                const fields = [
                    {
                        object: this.props.server,
                        name: 'name',
                        propertyName: 'Наименование',
                        placeholder: 'Введите наименование',
                        type: 'text',
                        columnSettings: '3',
                        columnClassName: 'text-left align-self-center',
                        inputClassName: '',
                        row: true,
                        invalid: true,
                        disableSubmit: this.disableSubmit,
                        component: StringInput
                    },
                    {
                        object: this.props.server,
                        name: 'description',
                        propertyName: 'Описание',
                        placeholder: 'Введите описание',
                        type: 'text',
                        columnSettings: '3',
                        columnClassName: 'text-left align-self-center',
                        inputClassName: '',
                        row: true,
                        invalid: true,
                        disableSubmit: this.disableSubmit,
                        component: StringInput
                    },
                    {
                        object: this.props.server,
                        name: 'solution',
                        propertyName: 'Решение',
                        modelsList: this.props.servers.solutions,
                        component: StringSelect
                    },
                    {
                        object: this.props.server,
                        name: 'cores',
                        propertyName: 'Кол-во ядер CPU узла',
                        type: 'range',
                        columnSettings: '3',
                        columnClassName: 'text-left align-self-center',
                        placeholder: '',
                        inputClassName: '',
                        row: true,
                        min: '2',
                        max: '80',
                        step: '1',
                        component: NumberInput
                    },
                    {
                        object: this.props.server,
                        name: 'RAM',
                        propertyName: 'ОЗУ узла',
                        type: 'range',
                        columnSettings: '3',
                        columnClassName: 'text-left align-self-center',
                        placeholder: '',
                        inputClassName: '',
                        row: true,
                        min: '1',
                        max: '32',
                        step: '1',
                        component: NumberInput
                    },
                    {
                        object: this.props.server,
                        name: 'nodes',
                        propertyName: 'Кол-во узлов',
                        type: 'range',
                        columnSettings: '3',
                        columnClassName: 'text-left align-self-center',
                        placeholder: '',
                        inputClassName: '',
                        row: true,
                        min: '1',
                        max: '16',
                        step: '1',
                        component: NumberInput
                    }
                ]
                return (
                    <CModal
                        show={this.props.isFormOpen}
                        onClose={() => {
                            this.props.resetFormData(); 
                            this.props.toggleForm();
                            this.props.servers.currentPage === 0 ? 
                            this.props.history.push(`/servers`) : 
                            this.props.history.push(`/servers?page=` + this.props.servers.currentPage)
                        }}
                        size="lg"
                    >
                        <CModalBody>
                            <CTabs>
                                <CNav variant="tabs" className="mb-2">
                                    <CNavItem>
                                        <CNavLink>
                                            Базовые параметры
                                        </CNavLink>
                                    </CNavItem>
                                    <CNavItem>
                                        <CNavLink>
                                            Сетевые настройки
                                        </CNavLink>
                                    </CNavItem>
                                    <CNavItem className="ml-auto">
                                        <CButtonClose 
                                            onClick={() => {
                                                this.props.resetFormData(); 
                                                this.props.toggleForm();
                                                this.props.servers.currentPage === 0 ? 
                                                this.props.history.push(`/servers`) : 
                                                this.props.history.push(`/servers?page=` + this.props.servers.currentPage)  
                                        }}/>
                                    </CNavItem>
                                </CNav> 
                                <CTabContent>
                                    <CTabPane>
                                        <Inputs
                                            fields={fields}
                                        />
                                    </CTabPane>
                                    <CTabPane>
                                        <DomainsTab {...props}/>
                                    </CTabPane>
                                </CTabContent>
                            </CTabs>
                        </CModalBody>
                        <CModalFooter className="border-0">
                            <CButton 
                                type="button" 
                                size="lg" 
                                color="danger"
                                className="mr-2" 
                                onClick={this.submit} 
                                disabled={this.props.server.name === '' || this.state.error !== '' ? true : false}
                            >
                                {this.props.match.url === '/servers/new' ? 'Добавить' : 'Записать'}
                            </CButton>
                        </CModalFooter>
                    </CModal>
                )
            }
} 

export default UseLocation(withRouter(ServersChangeForm))