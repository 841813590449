import CIcon from '@coreui/icons-react'
import { CButton } from '@coreui/react'
import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom'
import { userController } from '../../controller/OrmUserController'
import { OrmUserStoreType } from '../../store/OrmUserStore'
import Registration from '../userLogin/Registration'
import RegistrationSuccess from '../userLogin/RegistrationSuccess'
import UserLogin from '../userLogin/UserLogin'

export interface MatchType {
    isExact: boolean
    params: {elementId: string}
    path:string
    url: string
}
interface PropsType extends RouteComponentProps {
    match: MatchType
}

interface InjectedProps extends PropsType{
    userStore: OrmUserStoreType
}

interface StateType {
    isRegistrationOpen: boolean
    isRegistrationSuccess: boolean
}

@inject('userStore')
@observer class UserLoginContainer extends Component<PropsType, StateType> {
    constructor(props: PropsType) {
        super(props)
        this.state = {
            isRegistrationOpen: false,
            isRegistrationSuccess: false
        }
        this.login = this.login.bind(this)
        this.toggleRegistrationModal = this.toggleRegistrationModal.bind(this)
        this.toggleSuccessModal = this.toggleSuccessModal.bind(this)
        this.changeRegistrationSuccessFlag = this.changeRegistrationSuccessFlag.bind(this)
        this.loadPage = this.loadPage.bind(this)
    }
    get injected() {
        return this.props as InjectedProps
    }
    // This method is controlling url state

    loadPage(url: string) {
        if ( url === '/login/registration' ) {
           this.setState({
                isRegistrationOpen: true
           })
        } else if ( url === '/login/registration/success' ) {
            this.setState({
                isRegistrationSuccess: true
            })
        }
    }
    login(loginData: {email: string, password: string}) {
        userController.login(loginData)
        // this.props.history.push("/")
    }
    register(registrationData: any) {
        userController.register(registrationData)
    }
    toggleRegistrationModal() {
        this.setState({
            isRegistrationOpen: !this.state.isRegistrationOpen
        })
    }
    toggleSuccessModal() {
        this.setState({
            isRegistrationSuccess: !this.state.isRegistrationSuccess
        })
    }
    changeRegistrationSuccessFlag() {
        this.injected.userStore.setValue(this.injected.userStore.userLogin, 'isRegistrationSuccess', !this.injected.userStore.userLogin.isRegistrationSuccess)
    }
    render() { 
        return (
            !this.injected.userStore.userLogin.isAuth ? 
            <Switch>
                <Route exact path="/login" render={ () =>   <UserLogin 
                                                                userLogin={this.injected.userStore.userLogin} 
                                                                resetLoginData={this.injected.userStore.eraseData}
                                                                login={this.login}
                                                                toggleRegistrationModal={this.toggleRegistrationModal}
                                                            />}/>
                <Route exact path="/login/registration" render={ () =>  <Registration
                                                                            userLogin={this.injected.userStore.userLogin}
                                                                            toggleModal={this.toggleRegistrationModal}
                                                                            isModalOpen={this.state.isRegistrationOpen}
                                                                            resetLoginData={this.injected.userStore.eraseData}
                                                                            register={this.register}
                                                                            loadPage={this.loadPage}
                                                                        />}/>
                {this.injected.userStore.userLogin.isRegistrationSuccess && <Route path="/login/registration/success" render={ () =>    <RegistrationSuccess
                                                                                                                                            toggleModal={this.toggleSuccessModal}
                                                                                                                                            isModalOpen={this.state.isRegistrationSuccess}
                                                                                                                                            loadPage={this.loadPage}
                                                                                                                                            changeRegistrationSuccessFlag={this.changeRegistrationSuccessFlag}
                                                                                                                                        />}/>}
            </Switch>: 
            <div className="d-flex">
                <CButton 
                    color="danger" 
                    className="px-4 m-3 ml-auto" 
                    to={"/"}
                >
                    <CIcon name="cil-home" size="lg" className="mr-2"/>
                    На главную
                </CButton> 
            </div>
        )
    }
} 

export default withRouter(UserLoginContainer)
