import { CCol, CFormGroup, CInput, CLabel, CInvalidFeedback } from '@coreui/react'
import BaseInput from './BaseInput'

abstract class BaseSimpleInput<T> extends BaseInput<T> {

  render() {
    return (
      <CFormGroup row={this.props.row} className={this.props.wrapperClassName}>
        {this.props.propertyName !== '' &&  <CCol md={this.props.columnSettings} className={this.props.columnClassName}>
                                              <CLabel 
                                                htmlFor={this.props.name}
                                              >
                                                {this.props.propertyName}
                                                {
                                                  this.props.name === 'cores' || 
                                                  this.props.name === 'RAM' || 
                                                  this.props.name === 'nodes' ? 
                                                  (this.state.value !== '' ? 
                                                    ` (${this.state.value})`: 
                                                    ` (${this.renderValue(this.getValue())})`): 
                                                  ''
                                                }
                                              </CLabel>
                                            </CCol>
        }
        <CCol className={this.props.columnInputClassName}>
          <CInput
            invalid={this.props.invalid && (this.state.error !== '' ? true: false)}
            type={this.props.type}
            id={this.props.name}
            name={this.props.name}
            placeholder={this.props.placeholder}
            onChange={this.handleChange}
            value={this.renderValue(this.getValue())}
            size={this.props.size}
            className={this.props.inputClassName}
            step={this.props.step}
            min={this.props.min}
            max={this.props.max}
            readOnly={this.props.readonly}
          />
          <CInvalidFeedback>{this.state.error}</CInvalidFeedback>
        </CCol>
      </CFormGroup>
    )
  }
}

export default BaseSimpleInput
