import { observer } from 'mobx-react'
import BaseSimpleSelect from './BaseSimpleSelect'

@observer class StringSelect extends BaseSimpleSelect<string> {

  parseValue(value: string): string {
    return value
  }

  renderValue(value: string): string {
    return value
  }
}

export default StringSelect
