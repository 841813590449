import React, { Component } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

interface PropsType extends RouteComponentProps {
    
}

class MicroservicesContainer extends Component<PropsType> {
    render() { 
        return (
            <div>РАЗДЕЛ МИКРОСЕРВИСЫ</div>
        )
    }
} 

export default withRouter(MicroservicesContainer)
