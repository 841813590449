import { observer } from 'mobx-react'
import BaseSimpleInput from './BaseSimpleInput'

@observer class FloatNumberInput extends BaseSimpleInput<number> {

  parseValue(value: string): number {
    return Number(value)
  }

  renderValue(value: number): string {
    return String(value)
  }
}

export default FloatNumberInput
