import CIcon from "@coreui/icons-react";
import { CButton, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CHeader, CHeaderNav, CHeaderNavItem, CToggler } from "@coreui/react";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { UserBalanceType, UserLoginType } from "../store/OrmUserStore";

interface PropsType {
    logout(): void
    minimizeSidebar(): void
    toggleDisplaySidebar(): void
    userLogin: UserLoginType
    userBalance: UserBalanceType
    toggleRefillModal(status: boolean): void
}

@observer class Header extends Component<PropsType> {
    constructor(props: PropsType) {
        super(props)
        this.submit = this.submit.bind(this)
    }
    submit() {
        this.props.logout()
    }
    render() {
        return (
            <CHeader className="bg-dark border-0 mb-2 d-flex justify-content-between">
                <CHeaderNav>
                    { this.props.userLogin.isAuth &&    <CHeaderNavItem className="px-3 d-flex">
                                                            <CToggler
                                                                inHeader
                                                                className="ml-md-3  mr-3 d-lg-none"
                                                                onClick={this.props.toggleDisplaySidebar}
                                                            />
                                                            <CToggler
                                                                inHeader
                                                                className="mr-3 d-md-down-none"
                                                                onClick={this.props.minimizeSidebar}
                                                            />
                                                        </CHeaderNavItem>
                    }                                  
                </CHeaderNav>
                <CHeaderNav>
                    <CHeaderNavItem className="px-3" >
                        { this.props.userLogin.isAuth ?     <CDropdown className="mt-2">
                                                                <CDropdownToggle caret color="danger">
                                                                    <CIcon 
                                                                        name="cil-user" 
                                                                        size="lg" 
                                                                        className="mr-2"
                                                                    />
                                                                    {this.props.userLogin.user.principal !== undefined && this.props.userLogin.user.principal.name}
                                                                </CDropdownToggle>
                                                                <CDropdownMenu>
                                                                    <CDropdownItem>Баланс: {this.props.userBalance.pointBalanceConverted}</CDropdownItem>
                                                                    <CDropdownItem 
                                                                        onClick={() => this.props.toggleRefillModal(true)} 
                                                                        to={'/refill'}
                                                                    >
                                                                        Пополнение баланса
                                                                    </CDropdownItem>
                                                                    <CDropdownItem 
                                                                        onClick={this.submit}
                                                                    >
                                                                        <CIcon name="cil-account-logout" size="lg" className="mr-2"/>
                                                                        Выйти
                                                                    </CDropdownItem>
                                                                </CDropdownMenu>
                                                            </CDropdown> :
                                                            <CButton 
                                                                color="danger" 
                                                                className="px-4 m-3" 
                                                                to={"/login"}
                                                            >
                                                                <CIcon name="cil-exit-to-app" size="lg" className="mr-2"/>
                                                                Войти
                                                            </CButton>
                        }  
                    </CHeaderNavItem>
                </CHeaderNav>
            </CHeader>
        )
            
    }
}

export default Header