import React, { Component } from 'react'
import HeaderContainer from './HeaderContainer'
import Content from './Content'
import Sidebar from '../Sidebar'
import { OrmUserStoreType } from '../../store/OrmUserStore'
import { inject, observer } from 'mobx-react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { userController } from '../../controller/OrmUserController'
import { billingBalanceController } from '../../controller/OrmBillingBalanceController'
import { serverController } from '../../controller/OrmServerController'
const enhanceWithClickOutside = require('react-click-outside')

export interface MatchType {
    isExact: boolean
    params: {elementId: string}
    path:string
    url: string
}
interface PropsType extends RouteComponentProps {
    match: MatchType
}

interface InjectedProps extends PropsType{
    userStore: OrmUserStoreType
}

interface StateType {
    isShown: boolean
    isMinimized: boolean
    isRefillModalOpen: boolean
    isPaymentValidationModalOpen: boolean
    isPaymentResultModalOpen: boolean
    isRefillingBalance: boolean
}

@inject('userStore')
@observer class Layout extends Component<PropsType, StateType> {
    constructor(props: PropsType) {
        super(props)
        this.state = {
            isMinimized: false,
            isShown: true,
            isRefillModalOpen: false,
            isPaymentValidationModalOpen: false,
            isPaymentResultModalOpen: false,
            isRefillingBalance: false
        }
        this.toggleDisplaySidebar = this.toggleDisplaySidebar.bind(this)
        this.minimizeSidebar = this.minimizeSidebar.bind(this)
        this.handleClickOutside = this.handleClickOutside.bind(this)
        this.toggleRefillModal = this.toggleRefillModal.bind(this)
        this.togglePaymentResultModal = this.togglePaymentResultModal.bind(this)
        this.loadPage = this.loadPage.bind(this)
    }
    componentDidMount() {
        this.loadPage(this.props.match.url, '')
    }
    get injected() {
        return this.props as InjectedProps
    }
    loadPage(url: string, operationId: string) {
        if ( url === '/' ) {
            document.title = 'Личный кабинет НАТИВИ'
            userController.getUser(localStorage.getItem('userId'))
            serverController.registerNewUser(localStorage.getItem('userId'))
        } else if ( url === '/payment/validate' ) {
            this.setState({
                isPaymentValidationModalOpen: true
            })
            billingBalanceController.performRefillBalanceValidate(operationId).then( response => {
                if ( response.status === 200 ) {
                    if ( response.data.result ) {
                        this.props.history.push('/payment/success')
                    } else {
                        this.props.history.push('/payment/failure')
                    }
                    this.injected.userStore.setValue(this.injected.userStore.userBalance, 'result', response.data.result)
                }
            } )
        } else if ( url === '/payment/success' || url === '/payment/failure' ) {
            this.setState({
                isPaymentValidationModalOpen: false,
                isPaymentResultModalOpen: true
            })
        }
    }
    handleClickOutside() {
        this.setState({
            isShown: false
        })  
    }
    toggleDisplaySidebar() {
        this.setState({
            isShown: !this.state.isShown
        })
    }
    toggleRefillModal(status: boolean) {
        this.setState({
            isRefillModalOpen: status
        })
    }
    togglePaymentResultModal() {
        this.setState({
            isPaymentResultModalOpen: !this.state.isPaymentResultModalOpen
        })
    }
    minimizeSidebar() {
        this.setState({
            isMinimized: !this.state.isMinimized
        })
    }
    render() {
        return (
            <div className="c-app c-default-layout">
                { this.injected.userStore.userLogin.isAuth &&   <Sidebar 
                                                                    isMinimized={this.state.isMinimized}
                                                                    isShown={this.state.isShown}
                                                                    minimizeSidebar={this.minimizeSidebar}
                                                                />
                }
                <div className="c-wrapper bg-dark">
                    <HeaderContainer  
                        minimizeSidebar={this.minimizeSidebar}
                        toggleDisplaySidebar={this.toggleDisplaySidebar}
                        userLogin={this.injected.userStore.userLogin}
                        userBalance={this.injected.userStore.userBalance}
                        toggleRefillModal={this.toggleRefillModal}
                    />
                    <div className="c-body bg-dark">
                        { this.injected.userStore.userLogin.isAuth ? 
                            <Content 
                                toggleRefillModal={this.toggleRefillModal} 
                                isRefillModalOpen={this.state.isRefillModalOpen}
                                loadPage={this.loadPage}
                                isPaymentValidationModalOpen={this.state.isPaymentValidationModalOpen}
                                isPaymentResultModalOpen={this.state.isPaymentResultModalOpen}
                                togglePaymentResultModal={this.togglePaymentResultModal}
                                result={this.injected.userStore.userBalance.result}
                                isRefillingBalance={this.injected.userStore.userBalance.isRefillingBalance}
                            /> : 
                            <h1 className="text-black-50">Для доступа к функциям Нашего сервиса, пожалуйста, авторизируйтесь!</h1> 
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(enhanceWithClickOutside(Layout))