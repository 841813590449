import React, { Component } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

interface PropsType extends RouteComponentProps {
    
}

class ExtensionsContainer extends Component<PropsType> {
    render() { 
        return (
            <div>РАЗДЕЛ РАСШИРЕНИЯ</div>
        )
    }
} 

export default withRouter(ExtensionsContainer)
