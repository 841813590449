import { runInAction } from 'mobx'
import React, { Component } from 'react'

interface PropsType {
  object: any
  name: string
  placeholder: string
  type: string
  iconName?: string
  autoComplete?: string
  invalid?: boolean
  wrapperClassName?: string
  columnSettings?: string
  columnClassName?: string
  columnInputClassName?: string
  inputClassName?: string
  size?: string
  propertyName?: string
  row?: boolean
  min?: string
  max?: string
  step?: string
  readonly?: boolean
  disableSubmit?: (error: string) => void
  modelName?: string
  updateData?: (fieldName: string, item: any) => void
}

interface StateType {
  value: string
  error: string
}

abstract class BaseSimpleInput<T> extends Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props)
    this.state = {
      value: '',
      error: ''
    }
    this.handleChange = this.handleChange.bind(this)
  }
  handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      value: e.target.value
    })
    let regexp = /^[a-z0-9-]+$/
    let firstCharacter = /^[a-z]/
    let lastCharacter = /-$/
    let emptyLine = /^$/
    let value: T = this.parseValue(e.target.value)
    this.setValue(value)
    if ( (this.props.name === 'name' || this.props.name === 'description') && this.props.disableSubmit !== undefined ) {
      if ( emptyLine.test(e.target.value) ) {
        this.props.disableSubmit('')
        this.setState({
          error: ''
        })
      } else if ( !regexp.test(e.target.value) ) {
        this.props.disableSubmit('Ошибка')
        this.setState({
          error: `${this.props.propertyName} должно содержать строчные буквы латинского алфавита, цифры и дефисы`
        })
      } else if ( !firstCharacter.test(e.target.value) ) {
        this.props.disableSubmit('Ошибка')
        this.setState({
          error: `Первый символ должен быть буквой`
        })
      } else if ( lastCharacter.test(e.target.value) ) {
        this.props.disableSubmit('Ошибка')
        this.setState({
          error: `Последний символ не должен быть дефисом`
        })
      } else if ( (e.target.value.length < 3 || e.target.value.length > 63) && this.props.name === 'name' ) {
        this.props.disableSubmit('Ошибка')
        this.setState({
          error: `Допустимая длина — от 3 до 63 символов`
        })
      } else if ( e.target.value.length > 256 && this.props.name === 'description' ) {
        this.props.disableSubmit('Ошибка')
        this.setState({
          error: `Максимальная длина — 256 символов`
        })
      } else {
        this.props.disableSubmit('')
        this.setState({
          error: ''
        })
      }
    }
  }
  
  setValue(value: T) {
    let object: any = this.props.object
    runInAction(() => {
      object[this.props.name] = value
      if ( this.props.modelName === 'configurator' && this.props.updateData !== undefined ) {
        this.props.updateData(this.props.name, object[this.props.name])
      }
    })
  }
  
  getValue(): T {
    let object: any = this.props.object
    return object[this.props.name]
  }

  abstract parseValue(value: string): T

  abstract renderValue(value: T): string
}

export default BaseSimpleInput
