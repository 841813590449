import { billingBalanceApi } from "../api/OrmBillingBalanceApi"
import { userStore } from "../store/OrmUserStore"

class OrmBillingBalanceController {
    getBillingBalancePoints() {
        billingBalanceApi.getBillingBalancePoints().then( response => {
            if ( response.status === 200 ) {
                userStore.setValue(userStore.userBalance, 'pointBalanceConverted', response.data.pointBalanceConverted)
            }
        } )
    }
    refillBalance(amount: number) {
        billingBalanceApi.refillBalance(amount).then( response => {
            if ( response.status === 200 ) {
                const validateUrl = window.location.href + 'payment/validate'
                const failureUrl = window.location.href + 'payment/failure'
                const performingData = {
                    "documentType": "OrmBillingBalanceIncomeOperation",
                    "documentId": response.data.id,
                    "resolveCallbackUrl": validateUrl,
                    "rejectCallbackUrl": failureUrl
                }
                billingBalanceApi.performRefillBalance(performingData).then( response => {
                    if ( response.status === 200 ) {
                        window.close()
                        localStorage.setItem('isRefillingBalance', String(true))
                        window.open(response.data.formUrl)
                    }
                } )
            }
        } )
    }
   performRefillBalanceValidate(operationId: string | null) {
        return billingBalanceApi.performRefillBalanceValidate(operationId)
    }
}

export const billingBalanceController = new OrmBillingBalanceController()

export default OrmBillingBalanceController