import { CCardBody, CDataTable } from '@coreui/react'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { MatchType } from './DataTable'


interface PropsType extends RouteComponentProps {
    match: MatchType
    models: any
    name: string
    propertyName: string
    changeCurrentPage(page: number): void
    toggleForm(): void
    fields: Array<string>
    names: any
    scopedSlots: any
    clickableRows?: boolean
    onRowClick?: any
}

@observer class WarehousesTable extends Component<PropsType> {
            constructor(props: PropsType) {
                super(props)
                this.changeCurrentPage = this.changeCurrentPage.bind(this)
            }
            changeCurrentPage(page: number) {
                this.props.changeCurrentPage(page)
            }
            render() {
                const copyList = this.props.models.list.slice()
                // const totalPages = Math.ceil(this.props.models.count/this.props.models.itemsPerPage)
                const fields: Array<string> = this.props.fields
                const names = this.props.names
                return ( 
                    <CCardBody className="p-4">
                        <CDataTable
                            items={copyList}
                            fields={fields}  
                            addTableClasses="table__header servers__table"
                            noItemsViewSlot={`${this.props.propertyName} отсутствуют`}
                            columnHeaderSlot={names}
                            pagination
                            clickableRows={this.props.clickableRows}
                            onRowClick={this.props.onRowClick}
                            itemsPerPage={5}
                            size="sm"
                            scopedSlots={this.props.scopedSlots}
                        />
                    </CCardBody>
                )
            }
}

export default withRouter(WarehousesTable)