import { CForm } from '@coreui/react'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { UseLocation } from '../../../support/useLocationHoC'


interface PropsType extends RouteComponentProps {
    fields: any
}

@observer class Inputs extends Component<PropsType> {
            render() { 
                return (
                    <CForm className="form-horizontal d-flex flex-column justify-content-center">
                        {this.props.fields.map((item: any, index: number) => {
                            return  <item.component
                                        key={index}
                                        object={item.object}
                                        name={item.name}
                                        propertyName={item.propertyName !== undefined && item.propertyName}
                                        placeholder={item.placeholder !== undefined && item.placeholder}
                                        type={item.type !== undefined && item.type}
                                        columnSettings={item.columnSettings !== undefined && item.columnSettings}
                                        columnClassName={item.columnClassName !== undefined && item.columnClassName}
                                        row={item.row !== undefined && item.row}
                                        modelsList={item.modelsList !== undefined && item.modelsList}
                                        showModelsList={item.showModelsList !== undefined && item.showModelsList}
                                        inputClassName={item.inputClassName !== undefined && item.inputClassName}
                                        min={item.min !== undefined ? item.min: ''}
                                        max={item.max !== undefined ? item.max: ''}
                                        step={item.step !== undefined ? item.step: 'any'}
                                        invalid={item.invalid !== undefined && item.invalid}
                                        disableSubmit={item.disableSubmit !== undefined && item.disableSubmit}
                                    />
                        })}
                    </CForm>
                )
            }
} 

export default UseLocation(withRouter(Inputs))