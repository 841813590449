import { observer } from "mobx-react"
import React, { Component } from "react"
import { RouteComponentProps, withRouter } from "react-router-dom"
import { userController } from "../../controller/OrmUserController"
import { UserBalanceType, UserLoginType } from "../../store/OrmUserStore"
import Header from "../Header"


interface PropsType extends RouteComponentProps {
    minimizeSidebar(): void
    toggleDisplaySidebar(): void
    userLogin: UserLoginType
    userBalance: UserBalanceType
    toggleRefillModal(status: boolean): void
}

@observer class HeaderContainer extends Component<PropsType> {
    constructor(props: PropsType) {
        super(props)
        this.logout = this.logout.bind(this)
    }
    logout() {
        userController.logout()
        this.props.history.push("/login")
    }
    render() {
        return (
            <Header 
                minimizeSidebar={this.props.minimizeSidebar} 
                toggleDisplaySidebar={this.props.toggleDisplaySidebar}
                logout={this.logout}
                userLogin={this.props.userLogin}
                userBalance={this.props.userBalance}
                toggleRefillModal={this.props.toggleRefillModal}
            />
        )    
    }
}

export default withRouter(HeaderContainer)