import { CButton, CCard, CCardBody, CCardFooter, CCardHeader } from '@coreui/react'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import StringInput from '../ChangeFormInputs/StringInput'
import StringSelect from '../ChangeFormInputs/StringSelect'
import { MatchType } from '../containers/UserLoginContainer'

interface PropsType extends RouteComponentProps {
    match: MatchType
    editableItem: any
    modelsList: Array<any>
    typesList: Array<any>
    index: number
    updateData(fieldName: string, item: any, index: number): void
    editProperty(value: any): void
}

@observer class PropertyChangeForm extends Component<PropsType> {
            constructor(props: PropsType) {
                super(props)
                this.updateData = this.updateData.bind(this)
            }

            componentDidMount() {
                document.title = 'Личный кабинет НАТИВИ: Конфигуратор - редактирование свойства'
            }

            updateData(fieldName: string, item: any) {
                this.props.updateData(fieldName, item, this.props.index)
            }

            render() { 
                return (
                    <CCard className="border-0 align-items-center configurator__modal">
                        <CCardHeader className="border-0 font-weight-bold pb-0 configurator__modal text-white flex-grow-0">
                            Редактирование свойства
                        </CCardHeader>
                        <CCardBody className="configurator__modal flex-grow-0">
                            <StringInput
                                object={this.props.editableItem[this.props.index].item}
                                name="name"
                                propertyName="Наименование"
                                placeholder="Введите наименование"
                                type="text"
                                columnSettings="3"
                                columnClassName="text-left align-self-center text-white"
                                inputClassName="rounded-0"
                                columnInputClassName="align-self-center"
                                modelName="configurator"
                                updateData={this.updateData}
                            />
                            <StringSelect
                                object={this.props.editableItem[this.props.index].item}
                                modelsList={this.props.typesList}
                                name="type"
                                propertyName="Тип"
                                row={false}
                                modelName="configurator"
                                inputClassName="rounded-0"
                                updateData={this.updateData}
                            />
                            {this.props.editableItem[this.props.index].item.type === 'relation' &&      <StringSelect
                                                                                                            object={this.props.editableItem[this.props.index].item}
                                                                                                            modelsList={this.props.modelsList}
                                                                                                            name="relationModel"
                                                                                                            propertyName="Модель"
                                                                                                            row={false}
                                                                                                            modelName="configurator"
                                                                                                            inputClassName="rounded-0"
                                                                                                            updateData={this.updateData}
                                                                                                        />}
                        </CCardBody>
                        <CCardFooter className="border-0 configurator__modal flex-grow-0">
                            <CButton 
                                type="button" 
                                color="info"
                                className="w-100 rounded-0"
                                onClick={() => this.props.editProperty(this.props.editableItem[this.props.index])}
                            >
                                Сохранить
                            </CButton>
                        </CCardFooter>
                    </CCard>
                )
            }
} 

export default withRouter(PropertyChangeForm)