import axios from "axios"
import { config } from "../baseUrls"

const instance = axios.create({
    withCredentials: true,
    baseURL: config.url.API_URL
})

class OrmUserApi {
    login(loginData: {email: string, password: string}) {
        return instance.post(`users/login`, loginData)
    }
    logout() {
        return instance.post(`users/logout`, {}, {
            headers: {
                Authorization: localStorage.getItem('id')
            }
        })
    }
    getUser(id: string | null) {
        return instance.get(`users/${id}?filter={"include": "principal"}`, {
            headers: {
                Authorization: localStorage.getItem('id')
            }
        })
    }
    register(registrationData: any) {
        return instance.post('users/process', registrationData)
    }
}

export const userApi = new OrmUserApi()

export default OrmUserApi