import { CCard, CCardBody } from '@coreui/react'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import ControlPanel from './ControlPanel'
import Table from './Table'

export interface MatchType {
    isExact: boolean
    params: {elementId: string}
    path:string
    url: string
}

interface PropsType  {
    models: any
    name: string
    propertyName: string
    changeCurrentPage(page: number): void
    toggleForm(): void
    fields: Array<string>
    names: any
    scopedSlots: any
    clickableRows?: boolean
    onRowClick?: any
}

@observer class DataTable extends Component<PropsType> {
            render() {
                const props = {
                    models: this.props.models,
                    name: this.props.name,
                    propertyName: this.props.propertyName,
                    fields: this.props.fields,
                    names: this.props.names,
                    scopedSlots: this.props.scopedSlots,
                    clickableRows: this.props.clickableRows,
                    onRowClick: this.props.onRowClick,
                    changeCurrentPage: this.props.changeCurrentPage,
                    toggleForm: this.props.toggleForm
                }
                return ( 
                    <CCard className="m-3 p-2 layout d-flex m-auto">
                        <ControlPanel {...props}/>
                        <CCardBody className="wrapper p-0">
                            <Table {...props}/>
                        </CCardBody>
                    </CCard>
                )
            }
}

export default DataTable